import React from 'react';
import PropTypes from 'prop-types';
import SweetAlert from "react-bootstrap-sweetalert";
import { connect } from 'react-redux';
import { updateEditedList,updateEditMode, updateEditedCount,updateImagesData} from '../redux/actions/actions';
import axios from 'axios'

/** Material UI core components */
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import HistoryIcon from '@material-ui/icons/History';
import { IconButton } from "@material-ui/core";
import { Typography } from "@material-ui/core";
/** Mapbox React bindings */
import {
    Layer,
    GeoJSONLayer,
} from 'react-mapbox-gl';

const fillLayout = { visibility: 'visible' };
const fillPaint = {
    'fill-color': '#FFFFFF',
    'fill-opacity': 0
};

export class DistortableImageLayer extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            alert: null,
            polygonGeoJSON: {
                "type": "FeatureCollection",
                "features": [{
                    "id": "editPolygon",
                    "type": "Feature",
                    "properties": {
                        "sourceId": this.props.imagesData[this.props.imageKey].sourceId
                    },
                    "geometry": {
                        "type": "Polygon",
                        "coordinates": [this.props.imagesData[this.props.imageKey].coordinates]
                    }
                }]
            }
        }

        this.polygonEdited = this.polygonEdited.bind(this);
        this.enterEditMode = this.enterEditMode.bind(this);
        this.exitEditMode = this.exitEditMode.bind(this);
        this.saveEditMode = this.saveEditMode.bind(this);
        this.onToggleHover = this.onToggleHover.bind(this);
        this.warningEnterWithConfirmMessage = this.warningEnterWithConfirmMessage.bind(this);
        this.warningExitWithConfirmMessage = this.warningExitWithConfirmMessage.bind(this);
        this.successNotification = this.successNotification.bind(this);
        this.revertEditMode = this.revertEditMode.bind(this);
        this.imageClicked = this.imageClicked.bind(this);
    }

    componentWillUnmount() {
        let {map,imageKey} = this.props
        map.removeLayer("overlay-" + imageKey)
        map.removeSource('imstreamImage-' + imageKey)
    }

    warningNotification(text) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px", background: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(6px)', color: '#FFFFFF' }}
                    title={"Warning"}
                    onConfirm={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                >
                    <Typography variant='body2'>
                        {text}
                    </Typography>
                </SweetAlert>
            )
        });
    }

        /**
     * Shows a failure notification by changing the alert state to a SweetAlert component with a danger prop.
     * @param {*} text Main title header of the notification.
     * @public
     */
    failureNotification(text) {

        this.setState({
            alert: (
                <SweetAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" ,background: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(6px)', color: '#FFFFFF'}}
                    title={text}
                    showConfirm={false}
                    onCancel={() => this.hideAlert()}
                >
                </SweetAlert>
            )
        });
    }

    warningEnterWithConfirmMessage(e, sourceId) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px", background: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(6px)', color: '#FFFFFF' }}
                    title="Are you sure you want to enter Edit Mode for this image?"
                    onConfirm={() => this.enterEditMode(e)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes, let's edit."
                    cancelBtnText="Cancel"
                    showCancel
                >
                </SweetAlert>
            )
        });
    }

    warningExitWithConfirmMessage() {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px", background: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(6px)', color: '#FFFFFF' }}
                    title="Are you sure you want to exit Edit Mode for this image?"
                    onConfirm={() => this.exitEditMode()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    showCancel
                >
                    <Typography variant='body2'>
                        Please make sure that you have you clicked save, otherwise your progress will be lost.
                    </Typography>
                </SweetAlert>
            )
        });
    }

    successNotification(text) {
        this.setState({
          alert: (
            <SweetAlert
              success
              style={{ display: "block", marginTop: "-100px"}}
              title={text}
              onConfirm={() => this.hideAlert()}
              onCancel={() => this.hideAlert()}
              showConfirm={false}
              timeout={1000}
              >
            </SweetAlert>
          )
        });
      }

    /**
     * Hides any alert being shown on the UI when called.
     * @public
     */
    hideAlert() {
        this.setState({
            alert: null
        });
    }

    updateLocalImageStreams(flightId,cam,image,newCoords) {
        let db = this.props.db;
        var objectStore = db
        .transaction('imstreams', 'readwrite')
        .objectStore('imstreams');

        var index = objectStore.index("flight_id");
        index.get(flightId).onsuccess = function(event) {
        var data = event.target.result.object;
        let idx;
        data = JSON.parse(data);
        data.forEach((doc,index) => {
            if(doc.cam === cam && doc.image === image)
                    idx=index;
        });
        data[idx].GeoCoords = newCoords;
        data = JSON.stringify(data);
        objectStore.put({flight_id: flightId, object: data,id:event.target.result.id}).onsuccess = function(event) {
            console.log(event,"UPDATED")
        };
        };
    }

    enterEditMode() {
        let {draw,imagesData,imageKey}= this.props;
        let polygonGeoJSON = this.state.polygonGeoJSON;
        polygonGeoJSON.features[0].geometry.coordinates = [imagesData[imageKey].coordinates];
        this.setState({polygonGeoJSON})

        draw.add(polygonGeoJSON);
        draw.changeMode('direct_select', { featureId: 'editPolygon' });
        this.hideAlert();
        this.OnUpdateEditMode(true);
    }

    exitEditMode() {
        let {draw,imagesData,imageKey,map} = this.props;
        draw.delete('editPolygon');

        let source = map.getSource(imagesData[imageKey].sourceId);
        source.setCoordinates(imagesData[imageKey].coordinates);

        this.props.showMarkerColor(imageKey,'c4')
        this.OnUpdateEditMode(false);
        this.hideAlert();
    }

    saveEditMode() {
        let {imagesData,imageKey,editedList,editedCount,flightId} = this.props;
        imagesData[imageKey].coordinates = this.state.polygonGeoJSON.features[0].geometry.coordinates[0];

        this.OnUpdateImagesData(imagesData);
        if(!editedList.includes(imageKey))
        {
            editedList.push(imageKey)
            this.OnUpdateEditedCount(editedCount + 1);
            this.OnUpdateEditedList(editedList)
        }
        this.updateLayerGeoJSON()

        let cam = imagesData[imageKey].imageInfo.split('/')[0];
        let image = parseInt(imagesData[imageKey].imageInfo.split('/')[1].split('.')[0]);
        let newCoords = imagesData[imageKey].coordinates;
        let pushed_coords = [newCoords[0], newCoords[1], newCoords[2], newCoords[3]]
        //Updating MongoDB

        axios.put('/imstreams/edit?flightId='+flightId,{cam:cam,image:image, newCoords:pushed_coords}).then(response => {
            if(response.data === 'success')
                this.successNotification("Saved")
            else
                this.failureNotification("Failed to save, please try again or contact system adminstrator")
        })

        //Updating LocalStorage
        this.updateLocalImageStreams(flightId,cam,image,pushed_coords);
        this.exitEditMode()
    }

    revertEditMode() {
        let {draw,imagesData,imageKey,map} = this.props;
        let {polygonGeoJSON} = this.state;

        let source = map.getSource(imagesData[imageKey].sourceId);
        source.setCoordinates(imagesData[imageKey].coordinates);

        polygonGeoJSON.features[0].geometry.coordinates = [imagesData[imageKey].coordinates];
        this.setState({polygonGeoJSON});
        draw.add(this.state.polygonGeoJSON);
    }

    polygonEdited(e) {

        let {map} = this.props;
        let {polygonGeoJSON} = this.state;

        if (e.features[0].id === 'editPolygon') {
            let newCoordinates = e.features[0].geometry.coordinates[0];
            let source = map.getSource(e.features[0].properties.sourceId);
            source.setCoordinates([newCoordinates[0], newCoordinates[1], newCoordinates[2], newCoordinates[3]]);
            polygonGeoJSON.features[0].geometry.coordinates = [newCoordinates];
            this.setState({polygonGeoJSON})
        }
    }

    onToggleHover(event, cursor) {
        this.props.map.getCanvas().style.cursor = cursor;
    }

    TransitionDown(props) {
        return <Slide {...props} direction="down" />;
    }
    TransitionUp(props) {
        return <Slide {...props} direction="up" />;
    }

    imageClicked() {
        let {renderedImages, imageKey} = this.props;
        if (this.props.editModeOn === false)
        {
            // this.warningEnterWithConfirmMessage(e,polygonGeoJSON,sourceId)
            renderedImages.forEach(image => {
                if(image.key == imageKey)
                    if(image.show == true)
                        this.enterEditMode()

            })
        }
        else
            this.warningNotification("You are already in Edit Mode, please finish your current task and then click again.")
    }

    OnUpdateEditMode(val) {
        this.props.OnUpdateEditMode(val);
    }

    OnUpdateEditedCount(val) {
        this.props.OnUpdateEditedCount(val);
    }

    OnUpdateImagesData(val) {
        this.props.OnUpdateImagesData(val);
    }

    OnUpdateEditedList(val) {
        this.props.OnUpdateEditedList(val);
    }

    updateLayerGeoJSON() {
        let {map,imagesData,imageKey}= this.props;
        map.getSource('geojson-' + imagesData[imageKey].id).setData(this.state.polygonGeoJSON)
    }

    render() {

        const { imagesData,imageKey,editModeOn } = this.props

        let { polygonGeoJSON } = this.state

        return (

            <div>
                {this.state.alert}
                <Layer
                    id={imagesData[imageKey].id}
                    type={imagesData[imageKey].type}
                    sourceId={imagesData[imageKey].sourceId}
                    before={imagesData[imageKey].before}
                />
                {/* This is a workaround since Layer onClick is not working for now. https://github.com/alex3165/react-mapbox-gl/issues/782*/}
                <GeoJSONLayer
                    id={'geojson-' + imagesData[imageKey].id}
                    data={polygonGeoJSON}
                    fillLayout={fillLayout}
                    fillPaint={fillPaint}
                    fillOnClick={this.imageClicked}
                    fillOnMouseEnter={e => this.onToggleHover(e, 'pointer')}
                    fillOnMouseLeave={e => this.onToggleHover(e, '')}
                />
                <Snackbar
                    open={editModeOn}
                    TransitionComponent={this.TransitionUp}
                    ContentProps={{
                        'aria-describedby': 'message-id',
                    }}
                    message={<Typography variant="subtitle1" >EDIT MODE</Typography>}
                    action={[
                        <IconButton key="save" onClick={this.saveEditMode}>
                            <SaveIcon htmlColor='white' fontSize="large" />
                        </IconButton>,
                        <IconButton key="revert" onClick={this.revertEditMode}>
                            <HistoryIcon htmlColor='white' fontSize="large" />
                        </IconButton>,
                        <IconButton key="close" onClick={this.warningExitWithConfirmMessage}>
                            <CancelIcon htmlColor='white' fontSize="large" />
                        </IconButton>
                    ]}
                />
            </div>
        );
    }
}

DistortableImageLayer.propTypes = {
    imageKey: PropTypes.string.isRequired,
    map: PropTypes.object.isRequired,
    draw: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
    return {
        editModeOn: state.editModeOn,
        editedCount: state.editedCount,    
        imagesData: state.imagesData,
        editedList: state.editedList
    }
};

const mapActionsToProps = {
    OnUpdateEditMode: updateEditMode,
    OnUpdateEditedCount: updateEditedCount,
    OnUpdateImagesData: updateImagesData,
    OnUpdateEditedList: updateEditedList
};

export default connect(mapStateToProps, mapActionsToProps, null, { forwardRef: true })(DistortableImageLayer);