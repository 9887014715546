import { UPDATE_editedList} from '../actions/actions';

export default function settingsEditListReducer(state = '', { type, payload }) {
    switch (type) {
        case UPDATE_editedList:
            return payload.editedList;

        default:
            return state;
    }
}
