import React from "react";
import PropTypes from "prop-types";
// import { Route } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import loginPageStyle from "assets/jss/material-dashboard-pro-react/layouts/loginPageStyle.jsx";
import bgImage from "assets/img/login.jpeg";

import SweetAlert from "react-bootstrap-sweetalert";
import { Redirect } from 'react-router'

/** @material-ui/core components */
import InputAdornment from "@material-ui/core/InputAdornment";

/** @material-ui/icons */
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Lock from "@material-ui/icons/Lock";

/** Core components */
import GridContainer from "extra-components/Grid/GridContainer.jsx";
import GridItem from "extra-components/Grid/GridItem.jsx";
import CustomInput from "extra-components/CustomInput/CustomInput.jsx";
import Button from "extra-components/CustomButtons/Button.jsx";
import Card from "extra-components/Card/Card.jsx";
import CardBody from "extra-components/Card/CardBody.jsx";
import CardHeader from "extra-components/Card/CardHeader.jsx";
import CardFooter from "extra-components/Card/CardFooter.jsx";
import axios from 'axios';

class LoginPage extends React.Component {

  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      username: '',
      password: '',
      alert: null,
      show: false,
      intervalIsSet: false,
      flightid:'',
      redirectTo: '',
    };

    this.hashId = this.props.hashId
    if(this.hashId === undefined || this.hashId === '')
    {
      this.hashId = '000000000000000000000000'
    }
    this.hideAlert = this.hideAlert.bind(this);
    this.submitForLogin = this.submitForLogin.bind(this);
  }

  successNotification(text) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={text}
          onCancel={() => this.hideAlert()}
          showConfirm={false}
          >
        </SweetAlert>
      )
    });
  }

  failureNotification(text) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={text}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
        </SweetAlert>
      )
    });
  }
  
  componentDidMount() {

    if(this.props.loggedIn)
    {
      this.setState({
        redirectTo: '/mapview/' + this.hashId
      })
    }

    setTimeout(
      () => {
        this.setState({ cardAnimaton: "" });
      },
      700
    );
  }

  componentWillUnmount() {
    if (this.state.intervalIsSet) {
      clearInterval(this.state.intervalIsSet);
      this.setState({ intervalIsSet: null });
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  }

  submitForLogin(event) {

    event.preventDefault()
    console.log('handleSubmit')

    axios
        .post('/user/login', {
            username: this.state.username,
            password: this.state.password
        })
        .then(response => {
            console.log('login response: ')
            console.log(response)
            if (response.status === 200) {
                // update App.js state
                this.props.updateUser({
                    loggedIn: true,
                    username: response.data.username,
                    userRole: response.data.userRole
                })

                this.successNotification("Login Successful.")

                console.log(this.hashId)
                setTimeout(
                  function() {
                    this.setState({
                      redirectTo: '/mapview/'+this.hashId
                  })
                  }
                  .bind(this),
                  1000
              );

            }
        }).catch(error => {
            this.failureNotification("Login Failed. Please Try Again.")
            console.log(error);
            
        })
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  handleChange(event) {
    this.setState({flightid: event.target.value});

}

  render() {
    const { classes } = this.props;
    if (this.state.redirectTo.length) 
      return <Redirect to={{ pathname: this.state.redirectTo, flightid: this.state.flightid }} />
  else
    return (
      <div>
        <div className={classes.wrapper} ref="wrapper">
          <div className={classes.fullPage}>

            <div className={classes.content}>
              <div className={classes.container}>
                <GridContainer justify="center">
                  {this.state.alert}
                  <GridItem xs={12} sm={6} md={4}>
                    <form id="login_form">
                      <Card login className={classes[this.state.cardAnimaton]}>
                        <CardHeader
                          className={`${classes.cardHeader} ${classes.textCenter}`}
                          color="info"
                        >
                          <h4 className={classes.cardTitle}>Log in</h4>
                        </CardHeader>
                        <CardBody>
                          <CustomInput
                            labelText="Unique ID"
                            id="username"
                            value={this.state.username}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: this.handleChange("username"),
                              endAdornment: (
                                <InputAdornment position="end">
                                  <AccountCircleIcon className={classes.inputAdornmentIcon} />
                                </InputAdornment>
                              )
                            }}
                          />
                          <CustomInput
                            labelText="Password"
                            id="password"
                            value={this.state.password}
                            formControlProps={{
                              fullWidth: true
                            }}
                            inputProps={{
                              onChange: this.handleChange("password"),
                              type: "password",
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Lock
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              )
                            }}
                          />
                        </CardBody>
                        <CardFooter className={classes.justifyContentCenter}>
                          <Button onClick={this.submitForLogin} color="info" simple size="lg" block>
                            Login
                          </Button>
                        </CardFooter>
                      </Card>
                    </form>
                  </GridItem>
                </GridContainer>
              </div>
            </div>
            <div
              className={classes.fullPageBackground}
              style={{ backgroundImage: "url(" + bgImage + ")" }}
            />
          </div>
        </div>
      </div>
    );
  }
}

LoginPage.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(LoginPage);
