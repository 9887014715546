import { UPDATE_editedCount } from '../actions/actions';

export default function settingseditedCountReducer(state = '', { type, payload }) {
    switch (type) {
        case UPDATE_editedCount:
            return payload.editedCount;

        default:
            return state;
    }
}

