/*eslint eqeqeq: [0]*/
function fetchWithTimeout( url, params, timeout ) {
    return new Promise( (resolve, reject) => {
        let controller = new AbortController();
        // Set timeout timer
        let timer = setTimeout(
            () => {
                controller.abort();
                reject( new Error('Request timed out') );
            },
            timeout
        );
        fetch( url, { ...params, signal: controller.signal } ).then(
            response => resolve( response ),
            err => reject( err )
        ).finally( () => clearTimeout(timer) );
    });
}

function fetchData( url, params, timeout, resolver = response => response.json(), ignoreStatus = false ) {
    return new Promise( (resolve, reject) => {
        
        fetchWithTimeout(url, params, timeout).then(
            response => {
                if(!ignoreStatus && response.status >= 400)
                {
                    response.text().then( 
                        text => reject( 
                            new Error('Bad response from server' + (text != '' ? ': ' + text : ''))
                    ));
                }
                else
                    resolve( resolver(response) );
            },
            err => reject( err )
        );
    });
}

function fetchSpecial( url, params, options ) {
    return new Promise( (resolve, reject) => {
        // Set timeout timer
        let timer = setTimeout(
            options.delayCallback,
            options.delay
        );

        fetchData( url, params, options.timeout, options.resolver, options.ignoreStatus ).then(
            response => resolve( response ),
            err => reject( err )
        ).finally( () => clearTimeout(timer) );
    });
}

export default {
    fetchWithTimeout,
    fetchData,
    fetchSpecial
};