/*eslint eqeqeq: [0]*/

// style
import './mapbox-geocoder.css'

/** React import */
import React from "react";
import { connect } from 'react-redux';
import {updateImagesData } from '../redux/actions/actions';

/** Extra components - Material Dashboard Pro */
import GridContainer from "extra-components/Grid/GridContainer.jsx";
import GridItem from "extra-components/Grid/GridItem.jsx";
import CircularIndeterminate from "extra-components/Progress/CircularIndeterminate";

/** Material UI core components */
import PropTypes from 'prop-types';
import { withStyles}from '@material-ui/core/styles';
import { Typography } from "@material-ui/core";


/** Mapbox React bindings */
import ReactMapboxGl, {
    Layer,
    ScaleControl,
    ZoomControl,
    RotationControl,
    GeoJSONLayer,
} from 'react-mapbox-gl';
 import DrawControl from 'react-mapbox-gl-draw';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import "react-map-gl-geocoder/dist/mapbox-gl-geocoder.css";
// import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css"

import MapboxGeocoder from "mapbox-gl-geocoder";
// import mapboxgl from "react-mapbox-gl"


/** Other imports */
import axios from 'axios'
import LoadingOverlay from 'react-loading-overlay';
import bbox from '@turf/bbox'
import SweetAlert from "react-bootstrap-sweetalert";
import styles from 'assets/styles.jsx';
import Sidebar from './Sidebar.jsx'

/** Main Components */
import DistortableImageLayer  from "./DistortableImageLayer.jsx";
import MeasurePopup from "./MeasurePopup.jsx";

import Utility from "helpers/utility";

import mapboxgl from 'mapbox-gl'
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;
const fs = require('fs');

let mapStyle = {
    "version": 8,
    "sources": {
    "raster-tiles": {
    "type": "raster",
    "tiles": [process.env.REACT_APP_BASE_TILES_DIR],
    "tileSize": 256
    }
    },
    "layers": [{
    "id": "simple-tiles",
    "type": "raster",
    "source": "raster-tiles",
    "minzoom": 0,
    "maxzoom": 22,
    }]
}

/** Mapbox component definition */
const Map = ReactMapboxGl({
    minZoom: 1,
    maxZoom: 22,
    attributionControl: false,
    accessToken: 'pk.eyJ1IjoiYXRsYXNtYXBnZW4iLCJhIjoiY2swbmxlN2M4MDB5ejNibWxjMXVvdGNvYSJ9.UsZbpfrkOq-cccfmnIzwPg',
});

/** Circle markers style */
const circleLayout = { visibility: 'visible' };
const circlePaint = {
    'circle-color': [
        'match',
        ['get', 'colorProp'],
        'c1', '#FFFFFF', //White
        'c2', '#FFFF66', //Yellow
        'c3', '#FF0000', //Red
        'c4', '#00FF00', //Green
        /* other */ '#ffa500' //Orange
    ],
    'circle-stroke-color': 'black',
    'circle-stroke-width': 2,
};

class MapView extends React.Component {


    constructor(props) {
        super(props);

        /** Mapview component local state definition. */
        this.state = {
            // mapnumber: 0,
            extrusion: 2,
            flightId: '',
            flightPolygon: {"type": "Feature",
            "properties": {},
            "geometry": {
                "type": "Polygon",
                "coordinates": [
                    [
                        25.751953125,
                        42.407234661551875
                      ],
                      [
                        24.9169921875,
                        32.84267363195431
                      ],
                      [
                        45.59326171875,
                        33.063924198120645
                      ],
                      [
                        45.3955078125,
                        42.827638636242284
                      ],
                      [
                        25.751953125,
                        42.407234661551875
                      ]
                ]}
            },
            center: [29.0489, 41.0830],
            zoom: [10],
            geojsons: {
                "type": "FeatureCollection",
                "features": []
            },
            imStreamImages: [],
            imStreamCoords: [],
            renderedImages: [],
            renderedLayers: {},
            markersGeoJSONWF: {
                "type": "FeatureCollection",
                "features": []
            },
            markersGeoJSONMF: {
                "type": "FeatureCollection",
                "features": []
            },
            measurePopups: [],
            currentpos: {
                "currentlat": 0,
                "currentlng": 0,
                "currentzoom": 0,
		"currentlocation": null,
            },
            loading: true,
            loadingImages: {},
            loadingImstreams: false,
            alert: null,
            loadMap: false,
            imageNamePopup: null,
            flightbasetiles: "",
            flightimages: "",
            flighttilesMf: "",
            flighttilesWf: "",
	    counter:0
        }

        /** Functions bindings. */
        this.ext_increase_Clicked = this.ext_increase_Clicked.bind(this);
        this.ext_decrease_Clicked = this.ext_decrease_Clicked.bind(this);
        this.tree_d_buildings = this.tree_d_buildings.bind(this);
        this.shareClicked = this.shareClicked.bind(this);
        this.goToCoordinates = this.goToCoordinates.bind(this);  
        this.startStitching = this.startStitching.bind(this);
        this.stitchbutton = this.stitchbutton.bind(this);
        this.handleLayerToggle = this.handleLayerToggle.bind(this);
        this.mapClicked = this.mapClicked.bind(this);
        this.measureSelected = this.measureSelected.bind(this);
        this.onToggleHover = this.onToggleHover.bind(this);
        this.deleteImageClicked = this.deleteImageClicked.bind(this);
        this.moveDrawLayerTop = this.moveDrawLayerTop.bind(this);
        this.hideImage = this.hideImage.bind(this);
        this.showImage = this.showImage.bind(this);
        this.warningWithConfirmMessage = this.warningWithConfirmMessage.bind(this);
        this.warningWithConfirmMessageEdit = this.warningWithConfirmMessageEdit.bind(this);
        this.showMarkerColor = this.showMarkerColor.bind(this);

        /** 
         * Map component reference variable definition, to access Mapbox JS GL methods and properities not implemented in the React wrapper.
        * @public
         */
        this.mapRef = React.createRef();

        /** 
         * React Mapbox draw component reference variable definition, to access Mapbox Draw JS GL methods and properities not implemented in the React wrapper.
        * @public
         */
        this.drawRef = React.createRef();

        /**
         * Local Indexed DB initialization.
         * @public
         */
        this.initDB();
    }

    /**
     * Shows a failure notification by changing the alert state to a SweetAlert component with a danger prop.
     * @param {*} text Main title header of the notification.
     * @public
     */
    failureNotification(text) {

        this.setState({
            alert: (
                <SweetAlert
                    danger
                    style={{ display: "block", marginTop: "-100px" ,background: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(6px)', color: '#FFFFFF'}}
                    title={text}
                    showConfirm={false}
                    onCancel={() => this.hideAlert()}
                    onConfirm={() => this.hideAlert()}
                >
                </SweetAlert>
            )
        });
    }

    successNotification(text,body,timeout) {
        this.setState({
          alert: (
            <SweetAlert
              success
              style={{ display: "block", marginTop: "-100px" }}
              title={text}
              onCancel={() => this.hideAlert()}
              onConfirm={() => this.hideAlert()}
              showConfirm={false}
              timeout={timeout}
              >
            {body}
            </SweetAlert>
          )
        });
      }

    /**
     * Shows a waiting notification by changing the alert state to a SweetAlert component with a custom style.
     * @param {*} title Main title header of the notification.
     * @param {*} message Small description to be displayed under the title.
     * @param {*} waitTime Timeout period in milliseconds.
     * @public
     */
    waitingNotification(title, message, waitTime = 10000) {

        this.setState({
            alert: (
                <SweetAlert
                    style={{ display: "block", marginTop: "-100px",background: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(6px)', color: '#FFFFFF'}}
                    title={title}
                    onConfirm={() => this.hideAlert()}
                    showConfirm={false}
                    timeout={waitTime}
                    closeOnClickOutside={true}
                >
                    <GridContainer direction="column" justify="center">
                        <GridItem>
                            {message}
                        </GridItem>
                        <GridItem>
                            <CircularIndeterminate />
                        </GridItem>
                    </GridContainer>
                </SweetAlert>
            )
        });
    }

    warningWithConfirmMessage(key) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px",background: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(6px)', color: '#FFFFFF'}}
                    title="Are you sure you want to delete this image?"
                    onConfirm={() => this.deleteImageClicked(key)}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes,delete."
                    cancelBtnText="Cancel"
                    showCancel
                >
                </SweetAlert>
            )
        });
    }

    warningWithConfirmMessageEdit(key) {
        this.setState({
            alert: (
                <SweetAlert
                    warning
                    style={{ display: "block", marginTop: "-100px",background: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(6px)', color: '#FFFFFF'}}
                    title="Stitching on process. Are you sure you want to reinitilize process?"
                    onConfirm={() => this.startStitching()}
                    onCancel={() => this.hideAlert()}
                    confirmBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.success
                    }
                    cancelBtnCssClass={
                        this.props.classes.button + " " + this.props.classes.danger
                    }
                    confirmBtnText="Yes,delete."
                    cancelBtnText="Cancel"
                    showCancel
                >
                </SweetAlert>
            )
        });
    }

    /**
     * Hides any alert being shown on the UI when called.
     * @public
     */
    hideAlert() {
        this.setState({
            alert: null
        });
    }

    /**
     * This method is triggered as soon as the component is mounted. Retrive hash link related data from MongoDB and update all of the corresponding
     * state accordingly.
     * @public
     */
    componentDidMount() {

        // this.state.extrusion = 0;
        let hashId = this.props.match.params.hashId;
        console.log(hashId)
        axios.get('/hashlinks/getdata?hashid=' + hashId).then(response => {

            console.log(response);
            if (response.data === "Invalid Hash ID!" && hashId !== "000000000000000000000000")
                this.failureNotification("Invalid Hash ID!, please reload the page with a valid ID.");
            else 
            {
                let data = response.data[0];
                console.log('Data retrived: ', data);
                let keys = Object.keys(data);
                let zoom = this.state.zoom;

                keys.forEach(key => {
                    if (key === 'zoom') {
                        zoom[0] = data[key];
                        this.setState({ zoom });
                    }
                    else {
                        this.setState({ [key]: data[key] });
                    }
                })
            }
            this.getSources()
        });
    }

    /**
     * This method is triggered when the component recieves a state or prop update.
     * @param {*} prevProps The previous props of the component before the update.
     * @param {*} prevState The previous state of the component before the update.
     * @public
     */
    componentDidUpdate(prevProps, prevState) {

        Object.entries(this.props).forEach(([key, val]) =>
            prevProps[key] !== val && console.log(`Prop '${key}' changed`)
        );
        if (this.state) {
            Object.entries(this.state).forEach(([key, val]) =>
                prevState[key] !== val && console.log(`State '${key}' changed`)
            );
        }

        console.log(this.state.renderedImages)

        const {markersGeoJSONWF,markersGeoJSONMF} = this.state;
        if (this.mapRef.current !== null) {
            if(this.mapRef.getSource('geojson-wf') !== undefined)
                        this.mapRef.getSource('geojson-wf').setData(markersGeoJSONWF);
            if(this.mapRef.getSource('geojson-mf') !== undefined)
                        this.mapRef.getSource('geojson-mf').setData(markersGeoJSONMF);
        }

        if(prevProps.editModeOn !== this.props.editModeOn)
        {
            if(this.props.editModeOn)
            {
                let {renderedLayers} = this.state;
                let layersKeys = Object.keys(renderedLayers);
                layersKeys.forEach(layer => {
                    renderedLayers[layer] = false;
                });
                this.setState({renderedLayers})
            }
        }

    }

    /**
     * Initialize browser IndexedDB to be able to save and load image streams JSON retreived from MongoDB locally.
     * @public
     */
    initDB() {

        let request = indexedDB.open('MapViewDB', 1);

        request.onerror = () => {
            this.db = null;
        };
        request.onsuccess = () => {
            this.db = request.result;
        };
        request.onupgradeneeded = (e) => {
            let objectStore = e.target.result.createObjectStore('imstreams', { keyPath: 'id', autoIncrement: true });
            objectStore.createIndex('flight_id', 'flight_id', { unique: true });
        };
    }

    /**
     * Save image streams JSON to local indexedDB.
     * @param {*} imstreamsJSON The image stream object to be saved.
     * @param {*} flightId The flight id key of the image streams to be saved.
     * @public
     */
    saveLocalImagestreams(imstreamsJSON, flightId) {
        var tx = this.db.transaction("imstreams", "readwrite");
        var store = tx.objectStore("imstreams");
        store.add({ flight_id: flightId, object: imstreamsJSON });
    }

    /**
     * Load image streams JSON from local indexedDB.
     * @param {*} flightId The flight id key of the image streams to be loaded.
     * @public
     */
    loadLocalImagestreams(flightId) {
        return new Promise((resolve, reject) => {
            var tx = this.db.transaction("imstreams", "readonly");
            var store = tx.objectStore("imstreams");
            var index = store.index("flight_id");
            var request = index.get(flightId);
            request.onsuccess = () => {
                if (request.result !== undefined) {
                    resolve(request.result.object)
                } else {
                    reject();
                }
            };
        });
    }

    /**
     * Fetch image from server, add image source and layer to the Map and show the image.
     * @param {*} key The key of the image to be downloaded from server.
     * @param {*} firstLoad A boolean that indicates if the function being called on the first map load or on the marker click.
     * @public
     */
    addImageToMap(key, firstLoad) {

        let { imStreamImages, imStreamCoords, renderedImages,loadingImages} = this.state;
        let {imagesData} = this.props;
       // let url;
	console.log(process.env.REACT_APP_SOURCE)
       // if(process.env.REACT_APP_SOURCE === "LOCAL")
       //   url = "/imstreams/getlocalimage?url=" + process.env.REACT_APP_FLIGHTS_DIR + this.state.flightId + "/images/" + imStreamImages[key];
       // else if(process.env.REACT_APP_SOURCE === "SERVER")
       //     url = process.env.REACT_APP_FLIGHTS_DIR + this.state.flightId + "/images/" + imStreamImages[key];
       // else if(process.env.REACT_APP_SOURCE === "ONLINE")
       //     url = "https://f000.backblazeb2.com/file/" + this.state.flightId + "-atlas-smalls/" + imStreamImages[key];
	
	let url = "https://f000.backblazeb2.com/file/" + this.state.flightId + "-atlas-smalls/" + imStreamImages[key];
        console.log("imStreamImages[key]",imStreamImages[key])
       // if (this.state.flightimages !== "")
       // {
       //     url = this.state.flightimages + imStreamImages[key]
       // }
        console.log(url)
        loadingImages[key] = true;
        this.setState({ loadingImages });

        axios.get(url, { responseType: 'arraybuffer' }).then((response) =>
            new Buffer(response.data, 'binary').toString('base64')).then((response) => {

                let imageCoordinates = [
                    [imStreamCoords[key][0][0], imStreamCoords[key][0][1]],
                    [imStreamCoords[key][1][0], imStreamCoords[key][1][1]],
                    [imStreamCoords[key][2][0], imStreamCoords[key][2][1]],
                    [imStreamCoords[key][3][0], imStreamCoords[key][3][1]]
                ]
                this.mapRef.addSource('imstreamImage-' + key, {
                    type: 'image',
                    url: "data:image/jpg;base64," + response,
                    coordinates: imageCoordinates
                });

                let polygonCoordiantes = imageCoordinates;
                polygonCoordiantes.push(imageCoordinates[0])

                imagesData[key] = {
                    id:"overlay-" + key, 
                    type:'raster' ,
                    sourceId:'imstreamImage-'+key,
                    before:'gl-draw-polygon-fill-inactive.cold',
                    coordinates: polygonCoordiantes,
                    imageInfo:imStreamImages[key]
                }

                this.props.OnUpdateImagesData(imagesData);

                this[key+'_ref'] = React.createRef();
            })
            .catch((error) => {
                console.log(error);
            }).finally(() => {

                if (key !== undefined) {
                    if (firstLoad == false) {
                        this.hideImage(key)
                        this.showImage(key);
                        loadingImages[key] = false;
                    }
                    else {
                        renderedImages.forEach((element) => {
                            if (element.key === key && element.show === false)
                                this.mapRef.setLayoutProperty("overlay-" + key, 'visibility', 'none');
                        })

                        this.showMarkerColor(key)
                        loadingImages[key] = false;
                    }

                    this.setState({ loadingImages });
                }
            })

        if (!firstLoad && key !== undefined) {
            this.showImage(key);
            // this.waitingNotification("Image Loading", "Please wait until image is loaded from server.", 10e3);
        }
    }

    showMarkerColor(key) {

        let {markersGeoJSONWF,markersGeoJSONMF} = this.state
        let color;
        
        if(this.props.editedList.includes(key))
            color='c4'
        else
            color='c3'
            
        markersGeoJSONWF.features.forEach((feature,idx) => {
            if(feature.properties.key === parseInt(key))
                    markersGeoJSONWF.features[idx].properties.colorProp = color;

        });

        markersGeoJSONMF.features.forEach((feature,idx) => {
                if(feature.properties.key === parseInt(key))
                    markersGeoJSONMF.features[idx].properties.colorProp = color 
        });

        this.setState({markersGeoJSONMF,markersGeoJSONWF})

    }

    removeMarkerColor(key) {

        let {markersGeoJSONWF,markersGeoJSONMF} = this.state

        if(!(this.props.userRole === 'editor'))
        {
            markersGeoJSONWF.features.forEach((feature,idx) => {
                if(feature.properties.key === parseInt(key))
                        markersGeoJSONWF.features[idx].properties.colorProp = 'c1';
            });
    
            markersGeoJSONMF.features.forEach((feature,idx) => {
                    if(feature.properties.key === parseInt(key))
                        markersGeoJSONMF.features[idx].properties.colorProp = 'c2' 
            });    

        }

        this.setState({markersGeoJSONMF,markersGeoJSONWF})
    }

    /**
     * Make the image visible on the map. If the image is not on the map, then it adds the image key and a show parameter of true to the renderedImages state.
     * If the images is on the map then it just updates show parameter to true.
     * @param {*} key The key of the image to be shown on the map.
     * @public
     */
    showImage(key) {

        let renderedImages = this.state.renderedImages;
        let existFlag = 0;

        if(this.mapRef.getLayer("overlay-" + key, 'visibility'))
            {
                this.mapRef.setLayoutProperty("overlay-" + key, 'visibility', 'visible');
                this.mapRef.moveLayer('overlay-'+key)
            }
        renderedImages.forEach((element) => {

            if (element.key === parseInt(key)) {
                element.show = true;
            }

            else {
                existFlag++;
            }

        });

        if (existFlag === renderedImages.length)
            renderedImages.push({
                key: key,
                show: true
            });

        this.showMarkerColor(key)
    }

    /**
     * Make the image invisible on the map and sets show parameter of the corresponding key to false.
     * @param {*} key The key of the image to hidden from the map.
     * @public
     */
    hideImage(key) {

        let renderedImages = this.state.renderedImages;

        this.drawRef.current.draw.delete('editPolygon');
        renderedImages.forEach((element) => {
            if (element.key === parseInt(key))
                element.show = false;
        });

        if(this.mapRef.getLayer("overlay-" + key))
            this.mapRef.setLayoutProperty("overlay-" + key, 'visibility', 'none');
        // this.mapRef.setLayoutProperty("geojson-overlay-" + key, 'visibility', 'none');

        this.removeMarkerColor(key)
    }

    /**
     * Get all the image streams for the selected flight. If the flight id does exist in the browser indexedDB then fetch it from the indexedDB, else fetch
     * the image streams from MongoDB. Also, fetch all the images for keys in renderedImages, finally hide loading overlay and show the map.
     * @public
     */
    fetchImageStreams() {

        let {renderedImages} = this.state;

        this.setState({loadingImstreams: true});

	console.log(this.state.flightId)

        // this.loadLocalImagestreams(this.state.flightId)
        //     .then(response => {
        //         this.parseImageStreams(JSON.parse(response));
        //         renderedImages.forEach(image => {
        //             this.addImageToMap(image.key, true);
        //         });


          //   })
          //   .catch(() => {

                axios.get('/imstreams/get?flightId=' + this.state.flightId).then((response) => {
                    console.log(response.data)
                    this.parseImageStreams(response.data);
                    this.saveLocalImagestreams(JSON.stringify(response.data), this.state.flightId);
                })
                    .catch((error) => {
                        console.log(error);
                    }).finally(() => {
			
			console.log('fetchImageStreams INSIDE finally()')
			console.log('renderedImages: ', renderedImages)

                    renderedImages.forEach(image => {
                        this.addImageToMap(image.key, true);
                    });

                    })
            // })
    }

    /**
     * Add WF and MF tile sources to the map. <Source> React component was avoided because it updates whenever the component is updated,
     * which caused glitches and bad UX.
     * @public
     */
    addTileSources() {

	console.log("updated")
        // const polygonBbox = bbox(this.state.flightPolygon);

        let flightId = this.state.flightId === '20190720' ? this.state.flightId + "-1" : this.state.flightId; //Hardcoded because bucket naming issue
        let urlWfPNG;
        let urlWfJPG;
        let urlMfPNG;
        let urlMfJPG;
        let urlMf1PNG;
        let urlMf1JPG;
        if(process.env.REACT_APP_SOURCE === "LOCAL")
        {
            urlWfPNG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedWf/a{quadkey}.png";
            urlWfJPG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedWf/a{quadkey}.jpg";
            urlMfPNG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedMf/a{quadkey}.png";
            urlMfJPG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedMf/a{quadkey}.jpg";    
/*
            urlWfPNG = "/tilesapi/wf?tile=a{quadkey}.png&flightId="+flightId
            urlWfJPG = "/tilesapi/wf?tile=a{quadkey}.jpg&flightId="+flightId
            urlMfPNG = "/tilesapi/mf?tile=a{quadkey}.png&flightId="+flightId
            urlMfJPG = "/tilesapi/mf?tile=a{quadkey}.jpg&flightId="+flightId
*/
        }
        else if (process.env.REACT_APP_SOURCE === "SERVER")
        {
            urlWfPNG = process.env.REACT_APP_FLIGHTS_DIR + flightId + "/processed/stitchedWf/a{quadkey}.png"
            urlWfJPG = process.env.REACT_APP_FLIGHTS_DIR + flightId + "/processed/stitchedWf/a{quadkey}.jpg"
            urlMfPNG = process.env.REACT_APP_FLIGHTS_DIR + flightId + "/processed/stitchedWf/a{quadkey}.png"
            urlMfJPG = process.env.REACT_APP_FLIGHTS_DIR + flightId + "/processed/stitchedWf/a{quadkey}.jpg"
        }
        else if (process.env.REACT_APP_SOURCE === "ONLINE")
        {
            urlWfPNG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedWf/{z}/{x}/{y}.png";
            urlWfJPG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedWf/a{quadkey}.jpg";
            urlMfPNG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedMf/a{quadkey}.png";
            urlMfJPG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedMf/a{quadkey}.jpg";    
/*
            urlWfPNG = "https://" + flightId + ".s3.eu-central-1.amazonaws.com/wf/a{quadkey}.png";
            urlWfJPG = "https://" + flightId + ".s3.eu-central-1.amazonaws.com/wf/a{quadkey}.jpg";
            urlMfPNG = "https://" + flightId + ".s3.eu-central-1.amazonaws.com/a{quadkey}.png";
            urlMfJPG = "https://" + flightId + ".s3.eu-central-1.amazonaws.com/a{quadkey}.jpg";    
*/
        }
/*
        if(this.state.flightId === '20200730')
        {
            urlWfPNG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedWf/a{quadkey}.png";
            urlWfJPG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedWf/a{quadkey}.jpg";
            urlMfPNG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedMf_1/a{quadkey}.png";
            urlMfJPG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedMf_1/a{quadkey}.jpg";    
            urlMf1PNG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedMf_2/a{quadkey}.png";
            urlMf1JPG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedMf_2/a{quadkey}.jpg";    
        }
*/
        if(this.state.flightId === '20210221')
        {
            urlWfPNG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedWf/a{quadkey}.png";
            urlWfJPG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedWf/a{quadkey}.jpg";
            urlMfPNG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedMf/{z}/{x}/{y}.png";
            urlMfJPG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedMf/{z}/{x}/{y}.jpg";    
        }

	if(this.state.flightId === '20210422')
        {
            urlWfPNG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedWf/{z}/{x}/{y}.png";
            urlWfJPG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedWf/{z}/{x}/{y}.jpg";
            urlMfPNG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedMf/{z}/{x}/{y}.png";
            urlMfJPG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedMf/{z}/{x}/{y}.jpg";
        }
	
	if(this.state.flightId === '20210423')
        {
            urlWfPNG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedWf/{z}/{x}/{y}.png";
            urlWfJPG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedWf/{z}/{x}/{y}.jpg";
            urlMfPNG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedMf/{z}/{x}/{y}.png";
            urlMfJPG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedMf/{z}/{x}/{y}.jpg";
        }

        if(this.state.flightId === '20210327')
        {
            urlWfPNG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedWf/{z}/{x}/{y}.png";
            urlWfJPG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedWf/{z}/{x}/{y}.jpg";
            urlMfPNG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedMf/{z}/{x}/{y}.png";
            urlMfJPG = "https://" + flightId + "-atlas.s3.eu-central-1.amazonaws.com/stitchedMf/{z}/{x}/{y}.jpg";
        }

        if(this.state.flightId === '20190716')
        {
            urlWfPNG = "https://" + flightId + ".s3.eu-central-1.amazonaws.com/stitchedWf/a{quadkey}.png";
            urlWfJPG = "https://" + flightId + ".s3.eu-central-1.amazonaws.com/stitchedWf/a{quadkey}.jpg";
            urlMfPNG = "https://" + flightId + ".s3.eu-central-1.amazonaws.com/stitchedMf/a{quadkey}.png";
            urlMfJPG = "https://" + flightId + ".s3.eu-central-1.amazonaws.com/stitchedMf/a{quadkey}.jpg";
        }


    
        /** Atlas Map Tiles Options */

        const RASTER_SOURCE_OPTIONS_MF = {
            "type": "raster",
            "tiles": [urlMfJPG],
            "tileSize": 256,
            "minzoom": 17,
            // "bounds": polygonBbox
        };

        const RASTER_SOURCE_OPTIONS_MF_PNG = {
            "type": "raster",
            "tiles": [urlMfPNG],
            "tileSize": 256,
            "minzoom": 17,
            // "bounds": polygonBbox
        };

        const RASTER_SOURCE_OPTIONS_MF_1 = {
            "type": "raster",
            "tiles": [urlMf1JPG],
            "tileSize": 256,
            "minzoom": 17,
            // "bounds": polygonBbox
        };

        const RASTER_SOURCE_OPTIONS_MF_1_PNG = {
            "type": "raster",
            "tiles": [urlMf1PNG],
            "tileSize": 256,
            "minzoom": 17,
            // "bounds": polygonBbox
        };

        const RASTER_SOURCE_OPTIONS_WF_PNG = {
            "type": "raster",
            "tiles": [urlWfPNG],
            "tileSize": 256,
            "maxzoom": 17,
            // "bounds": polygonBbox
        };

        const RASTER_SOURCE_OPTIONS_WF = {
            "type": "raster",
            "tiles": [urlWfJPG],
            "tileSize": 256,
            "maxzoom": 17,
            // "bounds": polygonBbox
        };

        this.mapRef.addSource("wf", RASTER_SOURCE_OPTIONS_WF);
        this.mapRef.addSource("wfpng", RASTER_SOURCE_OPTIONS_WF_PNG);
        this.mapRef.addSource("mf", RASTER_SOURCE_OPTIONS_MF);
        this.mapRef.addSource("mfpng", RASTER_SOURCE_OPTIONS_MF_PNG);
        this.mapRef.addSource("mf1", RASTER_SOURCE_OPTIONS_MF_1);
        this.mapRef.addSource("mf1png", RASTER_SOURCE_OPTIONS_MF_1_PNG);
        this.mapRef.addSource("mapbox-dem", {
            type: "raster-dem",
            url: "mapbox://mapbox.mapbox-terrain-dem-v1",
            tileSize: 512,
            maxZoom: 16,
          })
        this.mapRef.on('idle', () => {
        this.mapRef.setTerrain({ source: "mapbox-dem", exaggeration: ['interpolate', ['exponential', 1], ['zoom'], 9, this.state.extrusion, 13, 1] }) 
	})
        this.setState({ loading: false });

    }

    /**
     * Parse the image stream data received from fetchImageStreams method, it divides the data into 4 main arrays:
     * 1) imStreamImages: save all the images folder and name in one string to be used later in fetching the image (e.g. mf01/1021.JPG).
     * 2) imStreamCoords: save all the 4 corners coordinates for all the images to be used in projecting the image.
     * 3) markersGeoJSONWF: save all the WF images centerpoints in one GeoJSON object to be used later in showing image streams markers.
     * 4) markersGeoJSONMF: save all the MF images centerpoints in one GeoJSON object to be used later in showing image streams markers.
     * @param {*} data The image streams data fetched from MongoDB. 
     * @public
     */
    parseImageStreams(data) {
     
        var { imStreamImages, imStreamCoords, markersGeoJSONWF, markersGeoJSONMF } = this.state
        let wfColor,mfColor;
        if(this.props.userRole === 'editor')
        {
            wfColor= 'c3'
            mfColor= 'c3'
        }
        else
        {
            wfColor='c1'
            mfColor='c2'
        }
        imStreamImages = [];
        imStreamCoords = [];
        markersGeoJSONWF.features = [];
        markersGeoJSONMF.features = [];
        for (var i = 0; i < data.length; i++) {
            imStreamImages[i] = data[i]["cam"] + "/" + data[i]["image"] + ".JPG";
            imStreamCoords[i] = data[i]["GeoCoords"];

            if (data[i]["type"] === 'wf') {
                markersGeoJSONWF.features.push({
                    "type": "Feature",
                    "properties": {
                        "key": i,
                        "colorProp": wfColor//+ (Math.floor(Math.random() * 16) + 1).toString()
                    },
                    "geometry": {
                        "type": "Point",
                        "coordinates": data[i]["CenterPoint"]
                    }
                });
            }

            else if (data[i]["type"] === 'mf') {
                markersGeoJSONMF.features.push({
                    "type": "Feature",
                    "properties": {
                        "key": i,
                        "colorProp": mfColor //+ (Math.floor(Math.random() * 16) + 1).toString()
                    },
                    "geometry": {
                        "type": "Point",
                        "coordinates": data[i]["CenterPoint"]
                    }
                });
            }
        }
        this.setState({ imStreamImages, imStreamCoords, markersGeoJSONWF, markersGeoJSONMF,loadingImstreams: false});
    }

    /**
     * This method is triggered when the share button is clicked. It retrieves all the current data from map and add it to the MongoDB, then recieves the hashlink
     * for this data from MongoDB and show it in the browser URL bar.
     * @public
     */
    shareClicked() {

        let { flightId, renderedImages, renderedLayers, currentpos} = this.state;

        let centerObject = this.mapRef.getCenter();
        let center = [centerObject.lng, centerObject.lat];
        let zoom = this.mapRef.getZoom();

        let geojsons = this.getDrawGeojsons();

        let username = this.props.username;

        axios.post('/hashlinks/addhash', {
            flightId: flightId,
            center: center,
            zoom: zoom,
            geojsons: geojsons,
            renderedImages: renderedImages,
            renderedLayers: renderedLayers,
            username: username,
            currentpos: currentpos
        })
            .then(response => {

                this.props.history.push('/mapview/' + response.data._id);
                this.setState({
                    _id: response.data._id,
                });

                if (!response.data.errmsg) {
                    this.successNotification("Successfully shared!","Copy the URL from the URL bar in the top of the browser and start sharing it",3000)
                    console.log('Successfully added!');
                } else {
                    console.log('Failed to add!');
                }
            }).catch(error => {
                console.log('Server error: ');
                console.log(error);
            })
    }

    /**
     * Gets all the GeoJSON layers added using the draw tool from the Map component.
     * @public
     */
    getDrawGeojsons() {

        let geojsons = {};

        if (this.drawRef.current !== null) { geojsons = this.drawRef.current.draw.getAll() };

        return geojsons;
    }

    /**
     * This method handles the tiles and image streams layer toggling based on their name.
     * @param {*} event Checkbox component onClick fired event, it contains the name of the layer that was triggered.
     * @public
     */
    handleLayerToggle(event) {

        let {renderedLayers} = this.state;
        renderedLayers[event.target.value] = !renderedLayers[event.target.value];
        if(event.target.value == 'mf')
        {
            this.state.renderedLayers['wf'] = this.state.renderedLayers['mf']
        }
        this.setState({ renderedLayers });
        if(renderedLayers[event.target.value] === false)
            this.mapRef.moveLayer(event.target.value);
    };


    /**
     * This method is triggered when an image stream marker is clicked. It changes the map viewport based on the clicked marker center and zoom, and then call
     * the addImageToMap method to fetch the image and show it.
     * @public
     */
    markerClick = (event) => {
        let features = this.mapRef.queryRenderedFeatures(event.point);
        let key = features[features.length-1].properties.key;
        var zoom;
        if (this.state.renderedLayers['imstreamswf'])
            zoom = 14;
        else
            zoom = 17;
        let center = event.lngLat;
        this.mapRef.flyTo({
            center: center,
            speed: 0.8,
            zoom: zoom
        });

        if(this.mapRef.getSource("imstreamImage-" + key) === undefined && key !== undefined)
            this.addImageToMap(key, false);
    };

    /**
     * This method is triggered when the user hovers the mouse over an image stream marker. The mouse cursor becomes a pointer. 
     * @param {*} cursor  Value of the cursor style. (e.g. pointer or default)
     * @public
     */
    onToggleHover(event,cursor) {

        //Laggyy fix it later
        // console.log(event,cursor)
        // if(cursor === 'pointer' && event.features[0].properties["cluster"] === undefined)
        //     this.setState({imageNamePopup: 
        //     <Popup
        //         coordinates={[event.lngLat.lng,event.lngLat.lat]}
        //     >
        //         {this.state.imStreamImages[event.features[0].properties.key]}
        //     </Popup>})
        // else 
        //     this.setState({imageNamePopup:null})

        this.mapRef.getCanvas().style.cursor = cursor;
    }

    /**
     * Set the current longtitude and latitude values in the top right box based on map click.
     * @param {*} map The map element containing all of its data.
     * @param {*} e The event fired by the onClick map method. It contains the longtitude and latitude of the clicked point.
     * @public
     */
    mapClicked(map, e) {
        this.reverse_geocoder(e);
        setTimeout(
            () => {
                let pos = {"currentlat": e.lngLat.lat, "currentlng": e.lngLat.lng, "currentzoom": this.mapRef.transform._zoom, "currentlocation": this.state.location}
                this.setState({currentpos: pos})
                let measurePopups = this.state.measurePopups;
                let selectedIds = this.drawRef.current.draw.getSelectedIds();
                if (selectedIds.length === 0)
                    measurePopups = [];
                if(this.props.editModeOn)
                    this.drawRef.current.draw.changeMode('direct_select',  {featureId: 'editPolygon'})
                this.setState({measurePopups });
            },
            200
          );        
    }
    ext_increase_Clicked() {
        
        if (this.state.counter%2 === 0)
        {
            this.state.extrusion=0;
        }
        else
            this.state.extrusion=2;
        this.state.counter = this.state.counter + 1;

    }
    ext_decrease_Clicked() {
        
        let extrusions = 0.05;
        this.setState({extrusion: this.state.extrusion-extrusions});
        console.log("this.state.extrusion",this.state.extrusion)
    }
    reverse_geocoder(e) {

        console.log(e)
        let fUrl = "https://api.mapbox.com/geocoding/v5/mapbox.places/" + (e.lngLat.lng).toString()+","+(e.lngLat.lat).toString()+".json?access_token=pk.eyJ1IjoiYXRsYXNtYXBnZW4iLCJhIjoiY2swbmxlN2M4MDB5ejNibWxjMXVvdGNvYSJ9.UsZbpfrkOq-cccfmnIzwPg"

        Utility.fetchSpecial(fUrl,
            {
                method: 'GET'
            },
            {
                delay: 1500,
                delayCallback: () => this.waitingNotification("Getting selected flight ID..."),
                timeout: 30e3
            }
        ).then(data => {
                let location = data.features[2].place_name;
                this.setState({location: location})
                console.log("Place:",data.features[2].place_name);

        }).catch(err => {
            this.failureNotification(String(err));
        });
    }
    tree_d_buildings() {

        switch(this.state.mapnumber) {
            case 0:
                this.mapRef.setStyle('mapbox://styles/mapbox/light-v10')
                mapStyle = "mapbox://styles/mapbox/light-v10"
              break;
            case 1:
                this.mapRef.setStyle('mapbox://styles/mapbox/satellite-v9')
              break;
            case 2:
                this.mapRef.setStyle('mapbox://styles/mapbox/streets-v11')
            break;
            case 3:
                this.mapRef.setStyle('mapbox://styles/mapbox/outdoors-v11')
            break;
            case 4:
                this.mapRef.setStyle('mapbox://styles/mapbox/dark-v10')
            break;
            case 5:
                this.mapRef.setStyle('mapbox://styles/mapbox/satellite-streets-v11')
            break;
            case 6:
                this.mapRef.setStyle('mapbox://styles/mapbox/navigation-day-v1')
            break;
            case 7:
                // mapStyle = {
                //     "version": 8,
                //     "sources": {
                //     "raster-tiles": {
                //     "type": "raster",
                //     "tiles": [process.env.REACT_APP_BASE_TILES_DIR],
                //     "tileSize": 256
                //     }
                //     },
                //     "layers": [{
                //     "id": "simple-tiles",
                //     "type": "raster",
                //     "source": "raster-tiles",
                //     "minzoom": 0,
                //     "maxzoom": 22
                //     }]
                // }
                // this.mapRef.setStyle(mapStyle)
                window.location.reload();
            break;

          }

        this.state.mapnumber++;
        if (this.state.mapnumber == 8)
        {
            this.state.mapnumber = 0;
        }
        // this.state.changeMap = 0;
        // window.location.reload();
 
    }


    /**
     * This method is triggered when the Map component styles are loaded, after the map is loaded the below functions are called because they
     * depend on the map instance.
     * @param {*} el The map instance returned from onStyleLoad method.
     * @public
     */
    mapLoaded(el) {
        this.mapRef = el;
        this.addTileSources();
        this.fetchImageStreams();

        // var layers = this.mapRef.getStyle().layers;
        // var labelLayerId;
        // for (var i = 0; i < layers.length; i++) {
        //     if (layers[i].type === 'symbol' && layers[i].layout['text-field']) {
        //     labelLayerId = layers[i].id;
        //     break;
        //     }
        // }

        axios.get("/tilesapi/polygon?flightId="+this.state.flightId).then(response => {
            if(response.data === "")
            {
                console.log("Polygon error muted");
                // this.failureNotification("Flight polygon can't be found in flight directory or is corrupted. Please reload the page with a valid polygon data.")
            }
            else
            {
                this.setState({flightPolygon: response.data})
            }
        })
          this.drawRef.current.draw.add(this.state.geojsons)
        this.mapRef.setFog({
            'range': [-1, 20],
            'color': 'white',
            'horizon-blend': 0.1
            });
        /*Creating 3D Terrain */
       // this.mapRef.addSource("mapbox-dem", {
       //     type: "raster-dem",
       //     url: "mapbox://mapbox.mapbox-terrain-dem-v1",
       //     tileSize: 512,
       //     maxZoom: 16,
       //   })
        // Sky //
       //  this.mapRef.addLayer({
       //        id: "sky",
       //        type: "sky",
       //        paint: {
       //          "sky-type": "atmosphere",
       //          "sky-atmosphere-sun": [0.0, 90.0],
       //          "sky-atmosphere-sun-intensity": 15,
       //        },
       //      })
        // Gradiendt Sky //
         this.mapRef.addLayer({
             'id': 'sky',
             'type': 'sky',
             'paint': {
             // set up the sky layer to use a color gradient
             'sky-type': 'gradient',
             // the sky will be lightest in the center and get darker moving radially outward
             // this simulates the look of the sun just below the horizon
             'sky-gradient': [
             'interpolate',
             ['linear'],
             ['sky-radial-progress'],
             0.8,
             'rgba(135, 206, 235, 1.0)',
             1,
             'rgba(0,0,0,0.1)'
             ],
             'sky-gradient-center': [0, 0],
             'sky-gradient-radius': 90,
             'sky-opacity': [
             'interpolate',
             ['exponential', 0.1],
             ['zoom'],
             5,
             0,
             22,
             1
             ]
             }
             });
        //3D buildings works in style: mapbox://styles/mapbox/light-v10
        // this.mapRef.addLayer(
        //     {
        //     'id': 'add-3d-buildings',
        //     'source': 'composite',
        //     'source-layer': 'building',
        //     'filter': ['==', 'extrude', 'true'],
        //     'type': 'fill-extrusion',
        //     'minzoom': 15,
        //     'paint': {
        //     'fill-extrusion-color': '#aaa',
                
        //     // Use an 'interpolate' expression to
        //     // add a smooth transition effect to
        //     // the buildings as the user zooms in.
        //     'fill-extrusion-height': [
        //     'interpolate',
        //     ['linear'],
        //     ['zoom'],
        //     15,
        //     0,
        //     15.05,
        //     ['get', 'height']
        //     ],
        //     'fill-extrusion-base': [
        //     'interpolate',
        //     ['linear'],
        //     ['zoom'],
        //     15,
        //     0,
        //     15.05,
        //     ['get', 'min_height']
        //     ],
        //     'fill-extrusion-opacity': 0.6
        //     }
        //     },
                
        //     labelLayerId
        //     );
        // Geo code of specific region
        // this.mapRef.addControl(
        //     new MapboxGeocoder({
        //     accessToken: "pk.eyJ1IjoiYXRsYXNtYXBnZW4iLCJhIjoiY2swbmxlN2M4MDB5ejNibWxjMXVvdGNvYSJ9.UsZbpfrkOq-cccfmnIzwPg",
            
        //     // Limit seach results to Australia.
        //     countries: 'au',
             
        //     // Use a bounding box to further limit results
        //     // to the geographic bounds representing the
        //     // region of New South Wales.
        //     bbox: [139.965, -38.03, 155.258, -27.839],
             
        //     // Apply a client-side filter to further limit results
        //     // to those strictly within the New South Wales region.
        //     filter: function (item) {
        //     // returns true if item contains New South Wales region
        //     return item.context
        //     .map(function (i) {
        //     // ID is in the form {index}.{id} per https://github.com/mapbox/carmen/blob/master/carmen-geojson.md
        //     // This example searches for the `region`
        //     // named `New South Wales`.
        //     return (
        //     i.id.split('.').shift() === 'region' &&
        //     i.text === 'New South Wales'
        //     );
        //     })
        //     .reduce(function (acc, cur) {
        //     return acc || cur;
        //     });
        //     },
        //     mapboxgl: ReactMapboxGl
        //     })
        //     );
          
        this.mapRef.addControl(
            
            new MapboxGeocoder({
            accessToken: "pk.eyJ1IjoiYXRsYXNtYXBnZW4iLCJhIjoiY2swbmxlN2M4MDB5ejNibWxjMXVvdGNvYSJ9.UsZbpfrkOq-cccfmnIzwPg",
            mapboxgl: ReactMapboxGl,
            style: {position:'top-right'}
            })
            );

        //this.mapRef.on('idle', () => {
        //    this.mapRef.setTerrain({ source: "mapbox-dem", exaggeration: ['interpolate', ['exponential', 1], ['zoom'], 13, this.state.extrusion, 16, 0.05] }) 

            // linear : ['interpolate', ['linear'], ['zoom'], 10, 1, 15, 0.5]
            // exponential : ['interpolate', ['exponential', base], ['zoom'], 10, 1, 15, 0.5]
            // set base '0,x (like 0.1)' for bigger steps in start of interpolation. 

        //    })

        this.mapRef.addControl(new mapboxgl.FullscreenControl(),"bottom-left");
  
            
        }

    measureSelected() {

        let measurePopups = this.state.measurePopups;
        let selectedIds = this.drawRef.current.draw.getSelectedIds();
        selectedIds.forEach((id, key) => {
            if(id !== 'editPolygon')
            {
                let selected = this.drawRef.current.draw.get(id)
                measurePopups[key] = selected;
            }
        })

        this.setState({ measurePopups })
    }

    moveDrawLayerTop (e) {
	
        if(e.mode.split('_')[0] == 'draw') {
            let allLayers = this.mapRef.getStyle().layers;
            allLayers.forEach(layer => {
                if(layer.id.split('-')[1] === 'draw')
                    this.mapRef.moveLayer(layer.id)
            });
        }
    } 

    deleteImageClicked (key) {
        let renderedImages = this.state.renderedImages
        renderedImages.forEach((element,index) => {
            if (element.key === key)
                renderedImages.splice(index,1);
        });

        let {imagesData} = this.props;
        delete imagesData[key]
        this.props.OnUpdateImagesData(imagesData)
        this.removeMarkerColor(key);
        this.setState({renderedImages});
        this.hideAlert();
    }

    stitchbutton() {
        axios.get('/imstreams/checkstitching?flightId=' + this.state.flightId).then((response) => {
            if(response.data == true)
            {
                this.warningWithConfirmMessageEdit();
            }
            else
            {
                this.startStitching();
            }
        })
    }

    goToCoordinates(longtitude,latitude)
    {
        this.mapRef.flyTo({
            center: [longtitude,latitude],
            speed: 0.8,
            zoom: 17
        });
    }

    startStitching()
    {
        let editedimstreams
        let numberImages;
        axios.get('/imstreams/export?flightId=' + this.state.flightId + '&isedited=true').then((response) => {

            editedimstreams = response

            if(response.data.stitching == false)
            {
                console.log("There is stitching process at the server!")
            }
            else if(response.data.length > 0)
            {
                numberImages = response.data.length;
                console.log("Stitching Started with " +response.data.length+" image!")
                axios.put("http://localhost:1307/context/flight/select/" + this.state.flightId).then(response => {
                    axios.get('http://localhost:1307/process/stitch/live').then(response => {
                        if (response.data.success) {
                            this.successNotification("Stitched Successfully", "All the images you are edited stitched successfully.", 100)
                            axios.get('/imstreams/startStitch?flightId=' + this.state.flightId)
                            console.log(response)
                        } else {
                            this.failureNotification("Stitching Failed")
                            console.log(response)
                        }
                        }).catch(error => {
                        console.log('Stitching Error: ' + error)
                        this.failureNotification("Failed to stitch:" + error)
                    })
                });


                     
            this.waitingNotification("Stitching " + numberImages + " Images","Please wait until stitching finished",5000e3)

            }
            else
            {
                this.failureNotification("There is no edited image!")
                return;
            }
        })
        .catch((error) => {
            console.log(error);
        }).finally(() => {
            console.log("final")
        });

        this.waitingNotification("Stitching Images","Please wait until stitching finished",5000e3)

    }

    getSources () {
        if (this.state.flightId !== "" && this.state.flightId !== 0) 
        {
          console.log(this.state.flightId)
          axios.get('/imstreams/get_flight_info?flightId=' + this.state.flightId).then((response) => {
            if (response.data[0]) {
	      console.log(response);
              //this.successNotification("Sources Collected Successfully")
              this.setState({ flightbasetiles: response.data[0].base_tiles });
              this.setState({ flightimages: response.data[0].images });
              this.setState({ flighttilesWf: response.data[0].tileswf });
              this.setState({ flighttilesMf: response.data[0].tilesmf });
              this.setState({loadMap: true });    
            } else {
            //this.failureNotification("Failed to collect sources")
                this.setState({loadMap: true });
            }
            })
            .catch((error) => {
                console.log('Collecting Error: ' + error)
                //this.failureNotification("Failed to get sources:" + error)
            })
        }
        else
        {
          //this.failureNotification("Flight Id is empty")
          this.setState({loadMap: true }); 
        }
    }
    

    drawEdited(e) {
        if (e.features[0].properties.sourceId !== undefined)
            this[e.features[0].properties.sourceId.split('-')[1]+'_ref'].current.polygonEdited(e)
    }

    render() {
        let { 
            renderedImages,imStreamImages, 
            measurePopups, loadingImages, 
            loadingImstreams,imageNamePopup,renderedLayers} = this.state;
        
        let {imagesData} = this.props;

        const renderedMeasurePopups = measurePopups.map((selected,key) =>
            <MeasurePopup
                key={key}
                selected={selected}
            />
        )

        const keys = Object.keys(imagesData);
        const renderedImagesLayers = this.props.userRole === 'editor' ?  keys.map(imageKey => 
                <DistortableImageLayer 
                    {...this.props}
                    ref={this[imageKey+'_ref']}
                    key={imageKey} imageKey={imageKey}
                    draw={this.drawRef.current.draw} map={this.mapRef}
                    flightId={this.state.flightId}
                    showMarkerColor={this.showMarkerColor}
                    db={this.db}
                    renderedImages={this.state.renderedImages}
                />
        ) : keys.map(imageKey => 
                <Layer
                    key={imageKey}
                    id={imagesData[imageKey].id}
                    type={imagesData[imageKey].type}
                    sourceId={imagesData[imageKey].sourceId}
                    before={imagesData[imageKey].before}
                />
        )
        
        return (

            <LoadingOverlay
                active={this.state.loading}
                spinner
                text={
                    <div>
                        <Typography variant="h4">
                            Loading Atlas Map View...
                        </Typography>
                        <br></br>
                        <Typography variant="body2">
                            This is a WebGL based application, please make sure the device you are using is equipped with a GPU.
                        </Typography>
                    </div>
                }
            >

                {this.state.alert}
                {this.state.loadMap ?
                    <Map
                        style={mapStyle}
                        containerStyle={{
                            height: '100vh',
                            width: '100vw',
                            flex: 1,
                        }}
                        center={this.state.center}
                        zoom={this.state.zoom}
                        onStyleLoad={el => this.mapLoaded(el)}
                        onClick={this.mapClicked}
                    >
                        {/* Controls */}
                        <ScaleControl />
                        <ZoomControl style={{ top: 200 }} />
                        <RotationControl style={{ top: 260 }} />
                        {this.state.renderedLayers['imstreamswf'] ?
                            <GeoJSONLayer
                                id='geojson-wf'
                                data={this.state.markersGeoJSONWF}
                                circleLayout={circleLayout}
                                circlePaint={circlePaint}
                                circleOnMouseEnter={e => this.onToggleHover(e,'pointer')}
                                circleOnMouseLeave={e => this.onToggleHover(e,'')}
                                circleOnClick={this.markerClick}
                                sourceOptions={{
                                    cluster: true,
                                    clusterMaxZoom: 12, // Max zoom to cluster points on
                                    clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
                                }}
                            /> : null}

                        {this.state.renderedLayers['imstreamsmf'] ?
                
                            <GeoJSONLayer
                                id='geojson-mf'
                                data={this.state.markersGeoJSONMF}
                                circleLayout={circleLayout}
                                circlePaint={circlePaint}
                                circleOnMouseEnter={e => this.onToggleHover(e,'pointer')}
                                circleOnMouseLeave={e => this.onToggleHover(e,'')}
                                circleOnClick={this.markerClick}
                                sourceOptions={{
                                    cluster: true,
                                    clusterMaxZoom: 14, // Max zoom to cluster points on
                                    clusterRadius: 50 // Radius of each cluster when clustering points (defaults to 50)
                                }}
                            /> : null}

                        {this.state.renderedLayers['wf'] ?
                            <Layer type="raster" id="wf" sourceId="wf"/> : null}
                        {this.state.renderedLayers['wf'] ?
                            <Layer type="raster" id="wf-png" sourceId="wfpng" before={'wf'}/> : null}

                        {this.state.renderedLayers['mf'] ?
                            <Layer type="raster" id="mf" sourceId="mf"/> : null}

                        {this.state.renderedLayers['mf'] ?
                            <Layer type="raster" id="mf-png" sourceId="mfpng" before={'mf'} /> : null}

                        <DrawControl
                            ref={this.drawRef}
                            position={'top-right'}
                            onDrawSelectionChange={this.measureSelected}
                            onDrawModeChange={e => this.moveDrawLayerTop(e)}
                            onDrawLiveUpdate={e => {this.drawEdited(e)}
                            }
                        />
                        <Sidebar
                            {...this.props}
                            imStreamImages={imStreamImages}
                            renderedImages={renderedImages}
                            renderedLayers={renderedLayers}
                            loadingImages={loadingImages}
                            loadingImstreams={loadingImstreams}
                            shareClicked={this.shareClicked}
                            ext_increase_Clicked={this.ext_increase_Clicked}
                            ext_decrease_Clicked={this.ext_decrease_Clicked}
                            tree_d_buildings={this.tree_d_buildings}
                            goToCoordinates={this.goToCoordinates}
                            startStitching={this.startStitching}
                            stitchbutton={this.stitchbutton}
                            handleLayerToggle={this.handleLayerToggle}
                            deleteImageClicked={this.deleteImageClicked}
                            showImage={this.showImage}
                            hideImage={this.hideImage}
                            warningWithConfirmMessage={this.warningWithConfirmMessage}
                            warningWithConfirmMessageEdit={this.warningWithConfirmMessageEdit}
                            totalImages={this.state.imStreamImages.length}
                            flightId={this.state.flightId}
                            imStreamCoords = {this.state.imStreamCoords}
                            currentpos  = {this.state.currentpos}
                        />
                        {renderedMeasurePopups}
                        {imageNamePopup}
                        {renderedImagesLayers}
                    </Map>
                    : null}
            </LoadingOverlay>
        );
    }
}

MapView.propTypes = {
    classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
    return {
        editModeOn: state.editModeOn,
        editedCount: state.editedCount,
        imagesData: state.imagesData,
        editedList: state.editedList

    }
};
const mapActionsToProps = {
    OnUpdateImagesData: updateImagesData,
};
export default connect(mapStateToProps,mapActionsToProps)(withStyles(styles)(MapView));
