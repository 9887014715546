import { UPDATE_editMode } from '../actions/actions';

export default function settingsEditModeReducer(state = '', { type, payload }) {
    switch (type) {
        case UPDATE_editMode:
            return payload.editModeOn;

        default:
            return state;
    }
}
