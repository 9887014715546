import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom' //don't need to specify localhost url in axios http address
import { Provider } from 'react-redux';
import store from './redux/store';
ReactDOM.render(
	<Provider store={store}>
	<BrowserRouter>{/* Use forceRefresh={true} to force refresh on URL change */}
		<App />
	</BrowserRouter>
	</Provider>,
	document.getElementById('root')
)