
/** Redux Libraries */
import { createStore, combineReducers } from 'redux';

/** Reducers */
import settingsEditModeReducer from './reducers/editmode-reducer';
import settingseditedCountReducer from './reducers/editedcount-reducer';
import settingsImagesDataReducer from './reducers/imagesdata-reducers';
import settingsEditedListReducer from './reducers/editedlist-reducer';

const initState = {
    editModeOn: false,
    editedCount: 0,
    imagesData: {},
    editedList: [],
};

const rootReducer = combineReducers({
    editModeOn: settingsEditModeReducer,
    editedCount: settingseditedCountReducer,
    imagesData: settingsImagesDataReducer,
    editedList: settingsEditedListReducer

});


const store = createStore(
    rootReducer,
    initState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store
