import React, { Component } from 'react';
import axios from 'axios'
import { Route,Switch,Redirect} from 'react-router-dom'
// components
import MapView from 'components/MapView'
import LoginPage from 'components/LoginPage';
import MiniDashboard from 'components/MiniDashboard'

class App extends Component {
  constructor() {
    super()
    this.state = {
      loggedIn: false,
      username: null,
      userRole: null,
      loading: true
    }
    this.getUser = this.getUser.bind(this)
    this.componentDidMount = this.componentDidMount.bind(this)
    this.updateUser = this.updateUser.bind(this)
  }

  componentDidMount() {
    this.getUser()
  }

  updateUser (userObject) {
    this.setState(userObject)
  }

  getUser() {
    axios.get('/user/').then(response => {
      console.log('Get user response: ')
      console.log(response.data)
      if (response.data.user) {
        console.log('Get User: There is a user saved in the server session: ')
        this.setState({
          loggedIn: true,
          username: response.data.user.username,
          userRole: response.data.user.userRole
        })
      } else {
        console.log('Get user: no user');
        this.setState({
          loggedIn: false,
          username: null
        })
      }

      this.setState({loading: false})
    })
  }

  render() {
    
    let hashId = window.location.href.split('/')[4];
    const PrivateRoute = ({ isLoggedIn, path}) =>
    isLoggedIn
    ? <Route path={path} render={(props) => <MapView {...props} username={this.state.username} userRole={this.state.userRole} />}/>
    : <Redirect to="/"/>

    const PrivateDashboard = ({ isLoggedIn, path}) =>
    isLoggedIn
    ? <Route path={path} render={(props) => <MiniDashboard {...props}/>}/>
    : <Redirect to="/"/>
    return (  
        this.state.loading === false ?
        <Switch>
          <PrivateDashboard isLoggedIn={ this.state.loggedIn } path="/dashboard" />
          <PrivateRoute isLoggedIn={ this.state.loggedIn } path="/mapview/:hashId" />
          <Route path="/" render={() =>
            <LoginPage
              loggedIn={this.state.loggedIn}
              updateUser={this.updateUser}
              hashId = {hashId}
            />}/>
        </Switch>
        : <div></div>
    );
    }
}

export default App;