import React from "react";
import PropTypes from "prop-types";
// import { Route } from "react-router-dom";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// core components
import loginPageStyle from "assets/jss/material-dashboard-pro-react/layouts/loginPageStyle.jsx";

import SweetAlert from "react-bootstrap-sweetalert";

/** @material-ui/core components */
import InputAdornment from "@material-ui/core/InputAdornment";
import Select from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";

/** @material-ui/icons */
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import Lock from "@material-ui/icons/Lock";

/** Core components */
import GridContainer from "extra-components/Grid/GridContainer.jsx";
import GridItem from "extra-components/Grid/GridItem.jsx";
import CustomInput from "extra-components/CustomInput/CustomInput.jsx";
import Button from "extra-components/CustomButtons/Button.jsx";
import Card from "extra-components/Card/Card.jsx";
import CardBody from "extra-components/Card/CardBody.jsx";
import CardHeader from "extra-components/Card/CardHeader.jsx";
import CardFooter from "extra-components/Card/CardFooter.jsx";
import axios from 'axios';
import CircularIndeterminate from "extra-components/Progress/CircularIndeterminate";
import ButtonGroup from '@material-ui/core/ButtonGroup';


class MiniDashboard extends React.Component {

  constructor(props) {
    super(props);
    // we use this to make the card to appear after the page has been rendered
    this.state = {
      cardAnimaton: "cardHidden",
      userRole: 'viewer',
      username: '',
      password: '',
      flights: [],
      flightId: 0,
      flightIdDB:0,
      flightbasetiles: "",
      flightimages: "",
      flighttilesMf: "",
      flighttilesWf: "",
      convertWf: "",
      convertMf: "",
      hashId: '',
      longtitude: 0,
      latitude: 0,
      zoom: 0,
      alert: null,
      intervalIsSet: false,
      wfimstreamsFs: [],
    };

    this.hideAlert = this.hideAlert.bind(this);
    this.submitForSignup = this.submitForSignup.bind(this);
    this.createHashlink = this.createHashlink.bind(this);
    this.importDataToDB = this.importDataToDB.bind(this);
    this.converImstreams = this.converImstreams.bind(this);
    this.updateSources = this.updateSources.bind(this);
    this.getSources = this.getSources.bind(this);
    this.wf_initial_estimates = this.wf_initial_estimates.bind(this);
    this.wf_geoblock = this.wf_geoblock.bind(this);
    this.wf_selection = this.wf_selection.bind(this);
    this.mf_matcher = this.mf_matcher.bind(this);
    this.mf_none = this.mf_none.bind(this);
    this.convertandimport = this.convertandimport.bind(this);

  }

  successNotification(text) {
    this.setState({
      alert: (
        <SweetAlert
          success
          style={{ display: "block", marginTop: "-100px" }}
          title={text}
          onCancel={() => this.hideAlert()}
          onConfirm={() => this.hideAlert()}
          showConfirm={false}
          timeout={1500}
          >
        </SweetAlert>
      )
    });
  }

  failureNotification(text) {
    this.setState({
      alert: (
        <SweetAlert
          danger
          style={{ display: "block", marginTop: "-100px" }}
          title={text}
          onConfirm={() => this.hideAlert()}
          onCancel={() => this.hideAlert()}
          confirmBtnCssClass={
            this.props.classes.button + " " + this.props.classes.success
          }
        >
        </SweetAlert>
      )
    });
  }

  waitingNotification(title, message, waitTime = 10000) {

    this.setState({
        alert: (
            <SweetAlert
                style={{ display: "block", marginTop: "-100px",background: 'rgba(0, 0, 0, 0.7)', backdropFilter: 'blur(6px)', color: '#FFFFFF'}}
                title={title}
                onConfirm={() => this.hideAlert()}
                showConfirm={false}
                timeout={waitTime}
                closeOnClickOutside={true}
            >
                <GridContainer direction="column" justify="center">
                    <GridItem>
                        {message}
                    </GridItem>
                    <GridItem>
                        <CircularIndeterminate />
                    </GridItem>
                </GridContainer>
            </SweetAlert>
        )
    });
  }
  
  componentDidMount() {

    this.fetchFlights()
    setTimeout(
      () => {
        this.setState({ cardAnimaton: "" });
      },
      700
    );
  }

  componentWillUnmount() {
    if (this.state.intervalIsSet) {
      clearInterval(this.state.intervalIsSet);
      this.setState({ intervalIsSet: null });
    }
  }

  handleChange = name => event => {
    this.setState({ [name]: event.target.value });
  }

  selectRole = () => event => {

    this.setState({ userRole: event.target.value });
}

  selectFlight = () => event => {

    this.setState({ flightIdDB: event.target.value });
    console.log(this.state.flightIdDB)
    this.fetchwfimstreams(event.target.value)
}

  selectwfimstreams = () => event => {

    this.setState({ convertWf: event.target.value });
    console.log(this.state.convertWf)
}

  submitForSignup(event) {

    console.log('sign-up handleSubmit, username: ')
		console.log(this.state.username)
		event.preventDefault()

		//request to server to add a new username/password
		axios.post('/user/', {
            userRole: this.state.userRole,
			username: this.state.username,
			password: this.state.password
		})
			.then(response => {
				console.log(response)
				if (!response.data.errmsg) {
                    console.log('successful signup')
                    this.successNotification("Created Successfully")
					this.setState({
						redirectTo: '/'
					})
				} else {
                    console.log('username already taken')
                    this.failureNotification("Username is already taken")
				}
			}).catch(error => {
				console.log('signup error: ')
                console.log(error)
                this.failureNotification("Failed to create user:" + error)

			})
  }

  wf_initial_estimates () {
    this.setState({convertWf: "initial_estimates.json"})
  }

  wf_geoblock () {
    this.setState({convertWf: "geoblock.json"})
  }

  wf_selection () {
    this.setState({convertWf: "wf_selection.json"})
  }

  mf_matcher () {
    this.setState({convertMf: "mf_matcher"})
  }

  mf_none () {
    this.setState({convertMf: "none"})
  }
  
  createHashlink () {
    let { flightId, longtitude, latitude,zoom } = this.state;

    let geojsons = {
      "type": "FeatureCollection",
      "features": []
    };
    let renderedImages = [];
    let renderedLayers = {
      imstreamswf: false,
      imstreamsmf: false,
      wf: false,
      mf: false
    }
    let username = "admin"

    axios.post('/hashlinks/addhash', {
      flightId: flightId,
      center: [longtitude,latitude],
      zoom: zoom,
      geojsons: geojsons,
      renderedImages: renderedImages,
      renderedLayers: renderedLayers,
      user: username
  })
      .then(response => {

          if (!response.data.errmsg) {
            this.setState({
              hashId: response.data._id,
            });
              console.log('Successfully added!');
              this.successNotification("Created Successfully")
          } else {
            this.failureNotification("Failed to create hashlink")
              console.log('Failed to add!');
          }
      }).catch(error => {
          console.log('Server error: ');
          this.failureNotification("Failed to create hashlink:" + error)
          console.log(error);
      })

    axios.get('/imstreams/get_flight_info?flightId=' + flightId).then((response) => {
    console.log(response.data)
    console.log(flightId)
    console.log("response.data")
    if (response.data[0]) {
      console.log("vardir")
    } 
    else {
      axios.post('/imstreams/insert_flight_info', {
        flightId: flightId,
        base_tiles: "",
        images: "/imstreams/getlocalimage?url=/home/mapgen/flights/"+ flightId +"/processed/smalls/",
        tilesMf: "/tilesapi/wf?url=/home/mapgen/flights/"+ flightId +"/processed/stitchedMf/",
        tilesWf: "/tilesapi/wf?url=/home/mapgen/flights/"+ flightId +"/processed/stitchedWf/",
        })
        .then(response => {
          if (!response.data.errmsg && response.data) {
            this.successNotification("Imported Successfully")
            console.log(response)
          } else {
            this.failureNotification("Importing Failed")
          }
        }).catch(error => {
          console.log('Importing Error: ' + error)
          this.failureNotification("Failed to import to database:" + error)
      })
      this.waitingNotification("Sources are being updated","Please wait until updating to the database is finished",10e3)
    }
    })
    .catch((error) => {
        console.log('Collecting Error: ' + error)
        this.failureNotification("Failed to get sources:" + error)
    })
  }

  importDataToDB () {
    console.log("giris")
    console.log(this.state.flightIdDB)
    if((this.state.flightIdDB !== 0) && (this.state.flightIdDB !== ""))
    {
      axios.post('/imstreams/import', {
        flightId: this.state.flightIdDB,
        })
        .then(response => {
          if (!response.data.errmsg) {
            this.successNotification("Imported Successfully")
          } else {
            this.failureNotification("Importing Failed")
          }
        }).catch(error => {
          console.log('Importing Error: ' + error)
          this.failureNotification("Failed to import to database:" + error)
      })

      this.waitingNotification("Importing " + this.state.flightIdDB + " Data","Please wait until importing to the database is finished",180e3)
    }
    else
    {
      this.failureNotification("FlightId is not entered")
    }
  }

  convertandimport () {
    this.converImstreams()
    this.importDataToDB()
  }

  converImstreams () {
    axios.post('http://localhost:1308/tools/convert/tomongo/'+this.state.flightIdDB+"/"+this.state.convertWf+"/"+this.state.convertMf).then(response => {
      if (response.data.success) {
          this.successNotification("Converted Successfully", "All the images you are edited converted successfully.", 100)
          console.log(response)
      } else {
          this.failureNotification("Converting Failed")
          console.log(response)
      }
      }).catch(error => {
      console.log('Converting Error: ' + error)
      this.failureNotification("Failed to convert:" + error)
  })
  
    this.waitingNotification("Converting " + this.state.flightIdDB + " Data","Please wait.",5e3)
  }

  updateSources () {
    if (this.state.flightId !== "" && this.state.flightIdDB !== 0) 
    {
      axios.post('/imstreams/post_flight_info', {
        flightId: this.state.flightIdDB,
        base_tiles: this.state.flightbasetiles,
        images: this.state.flightimages,
        tilesMf: this.state.flighttilesMf,
        tilesWf: this.state.flighttilesWf,
        })
        .then(response => {
          if (!response.data.errmsg && response.data) {
            this.successNotification("Imported Successfully")
            console.log(response)
          } else {
            this.failureNotification("Importing Failed")
          }
        }).catch(error => {
          console.log('Importing Error: ' + error)
          this.failureNotification("Failed to import to database:" + error)
      })
      //this.waitingNotification("Sources are being updated","Please wait until updating to the database is finished",10e3)
    }
    else
    {
      this.failureNotification("Flight Id is empty")
    }
  }

  getSources () {
    if (this.state.flightId !== "" && this.state.flightIdDB !== 0) 
    {
      console.log(this.state.flightIdDB)
      axios.get('/imstreams/get_flight_info?flightId=' + this.state.flightIdDB).then((response) => {
        //console.log(response.data)
        if (response.data[0]) {
          this.successNotification("Sources Collected Successfully")
          this.setState({ flightbasetiles: response.data[0].base_tiles });
          this.setState({ flightimages: response.data[0].images });
          this.setState({ flighttilesWf: response.data[0].tileswf });
          this.setState({ flighttilesMf: response.data[0].tilesmf });         
        } else {
          this.failureNotification("Failed to collect sources")
        }
        })
        .catch((error) => {
            console.log('Collecting Error: ' + error)
            this.failureNotification("Failed to get sources:" + error)
        })
    }
    else
    {
      this.failureNotification("Flight Id is empty")
    }
  }

  hideAlert() {
    this.setState({
      alert: null
    });
  }

  fetchwfimstreams(flight) {
    console.log(flight)
    axios.get('/imstreams/checkwfimstreams?flightId=' + flight).then((response) => {
      console.log(flight)
      console.log(response.data)
      console.log("response.data")
      response.data.forEach(element => {
        if(element.includes('.json'))
        {
          this.state.wfimstreamsFs.push(element)
        }
      });
      this.setState({wfimstreamsFs : this.state.wfimstreamsFs})
      console.log(this.state.wfimstreamsFs)
      })
      .catch((error) => {
          console.log('Collecting Error: ' + error)
          this.failureNotification("Failed to get sources:" + error)
      })  
  }

  fetchFlights() {
    axios.get('/imstreams/checkCollection?collectionname=flights').then(response => 
    {
      if(response.data == "fail")
      {
        axios.post('/imstreams/create_collection', {
          flightId: "00000000",
          base_tiles: "",
          images: "",
          tilesMf: "",
          tilesWf: "",
          })
          .then(response => {
            if (!response.data.errmsg && response.data) {
              this.successNotification("Imported Successfully")
              console.log(response)
            } else {
              this.failureNotification("Importing Failed")
            }
          }).catch(error => {
            console.log('Importing Error: ' + error)
            this.failureNotification("Failed to import to database:" + error)
        })
      }
      else
      {
        axios.get('/imstreams/get_flights', {
        })
        .then(response => {
          for (let index = 0; index < response.data.length; index++) {
            console.log("response")
            console.log(response.data.name)
            if(response.data[index].name)
            {
              this.state.flights.push(response.data[index].name)
            }
          }
        }).catch(error => {
          console.log('Flight could not fetched: ' + error)
        })
        console.log("flights exist")
      }
    });
}

  render() {
    const { classes } = this.props;
    const podOptions = this.state.flights.map((flight, key) =>
    (
        <MenuItem
            key={key}
            value={flight}>
            {flight}
        </MenuItem>
    ));
    const wfimstreams = this.state.wfimstreamsFs.map((imstream, key) =>
    (
        <MenuItem
            key={key}
            value={imstream}>
            {imstream}
        </MenuItem>
    ));

    return (
      <GridContainer justify="center">
        {this.state.alert}
        <GridItem xs={12} sm={6} md={4}>
          <form id="login_form">
            <Card login className={classes[this.state.cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="info"
              >
                <h4 className={classes.cardTitle}>User Control</h4>
              </CardHeader>
              <CardBody>
              <Typography>
                  User Role
              </Typography>
              <Select
                  value={this.state.userRole}
                  onChange={this.selectRole()}
                  input={<Input name="userRole" id="userRole-label-placeholder" />}
              >
                  <MenuItem key="viewer" value="viewer"> Viewer </MenuItem>
                  <MenuItem key="editor" value="editor"> Editor </MenuItem>
              </Select>
                <CustomInput
                  labelText="Unique ID"
                  id="username"
                  value={this.state.username}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: this.handleChange("username"),
                    endAdornment: (
                      <InputAdornment position="end">
                        <AccountCircleIcon className={classes.inputAdornmentIcon} />
                      </InputAdornment>
                    )
                  }}
                />
                <CustomInput
                  labelText="Password"
                  id="password"
                  value={this.state.password}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    onChange: this.handleChange("password"),
                    type: "password",
                    endAdornment: (
                      <InputAdornment position="end">
                        <Lock
                          className={classes.inputAdornmentIcon}
                        />
                      </InputAdornment>
                    )
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button onClick={this.submitForSignup}  className={this.props.classes.button} variant="contained">
                  Create User
                </Button>
              </CardFooter>
            </Card>
          </form>
          
          <Card login className={classes[this.state.cardAnimaton]}>
            <CardHeader
              className={`${classes.cardHeader} ${classes.textCenter}`}
              color="warning"
            >
              <h4 className={classes.cardTitle}>Flight Source Control</h4>
            </CardHeader>
            <CardBody>
            {/* <CustomInput
                labelText="Flight Id"
                id="flight_id"
                value={this.state.flightId}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "number",
                  onChange: this.handleChange("flightId"),
                }}
            /> */}
            <Select
                value={this.state.flightIdDB}
                onChange={this.selectFlight()}
                input={<Input name="flightIdDB" id="flightIdDB" />}
            >
                {podOptions}
            </Select>
            <CardFooter className={classes.justifyContentCenter}>
              <Button onClick={this.getSources} className={this.props.classes.button} variant="contained">
                Get Sources
              </Button>
            </CardFooter>
            {/* <CustomInput
                labelText="Base Tiles"
                id="base_tiles"
                value={this.state.flightbasetiles}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "string",
                  onChange: this.handleChange("flightbasetiles"),
                }}
            /> 
            <Typography> {this.state.flightimagesDB} </Typography> */}
            <CustomInput
                labelText="Images"
                id="images"
                value={this.state.flightimages}
                formControlProps={{
                  fullWidth: true
                }}
                
                inputProps={{
                  type: "string",
                  value: this.state.flightimages,
                  onChange: this.handleChange("flightimages"),
                }}
            />
            <CustomInput
                labelText="Mf Tiles"
                id="tilesMf"
                value={this.state.flighttilesMf}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "string",
                  value: this.state.flighttilesMf,
                  onChange: this.handleChange("flighttilesMf"),
                }}
            />
            <CustomInput
                labelText="Wf Tiles"
                id="tilesWf"
                value={this.state.flighttilesWf}
                formControlProps={{
                  fullWidth: true
                }}
                inputProps={{
                  type: "string",
                  value: this.state.flighttilesWf,
                  onChange: this.handleChange("flighttilesWf"),
                }}
            />
            </CardBody>
            <CardFooter className={classes.justifyContentCenter}>
              <Button onClick={this.updateSources} className={this.props.classes.button} variant="contained">
                Update Sources
              </Button>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
            <Card login className={classes[this.state.cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="danger"
              >
                <h4 className={classes.cardTitle}>Flight Control</h4>
              </CardHeader>
              <CardBody>
              <CustomInput
                  labelText="Flight ID"
                  id="flightId"
                  value={this.state.flightId}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: this.handleChange("flightId"),
                  }}
              />
              <CustomInput
                  labelText="Center Longtitude"
                  id="longtitude"
                  value={this.state.latitude}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: this.handleChange("longtitude"),
                  }}
              />
              <CustomInput
                  labelText="Center Latitude"
                  id="latitude"
                  value={this.state.longtitude}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: this.handleChange("latitude"),
                  }}
              />
              <CustomInput
                  id="zoom"
                  labelText="Zoom Level"
                  value={this.state.zoom}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: this.handleChange("zoom"),
                  }}
                />
              </CardBody>
              <CardFooter className={classes.justifyContentCenter}>
                <Button onClick={this.createHashlink} className={this.props.classes.button} variant="contained">
                  Create Hashlink
                </Button>
              </CardFooter>
              <Typography> {this.state.hashId === '' ? "Your Hash ID will be generated here" :this.state.hashId} </Typography>
            </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
            <Card login className={classes[this.state.cardAnimaton]}>
              <CardHeader
                className={`${classes.cardHeader} ${classes.textCenter}`}
                color="primary"
              >
                <h4 className={classes.cardTitle}>Database Control</h4>
              </CardHeader>
              <CardBody>
                <Select
                    value={this.state.flightIdDB}
                    onChange={this.selectFlight()}
                    input={<Input name="flightIdDB" id="flightIdDB" />}
                >
                    {podOptions}
                </Select>
                <br></br>
                <br></br>
                <Select
                    value={this.state.convertWf}
                    onChange={this.selectwfimstreams()}
                    input={<Input name="WfimstreamFs" id="WfimstreamFs" />}
                >
                    {wfimstreams}
                </Select>
              {/* <CustomInput
                  labelText="Flight ID"
                  id="flightIdDB"
                  value={this.state.flightIdDB}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "number",
                    onChange: this.handleChange("flightIdDB"),
                  }}
              /> */}
              </CardBody>
              <ButtonGroup color="info" aria-label="outlined primary button group">
                <Button onClick={this.wf_initial_estimates}>Initial Estimates</Button>
                <Button onClick={this.wf_geoblock}>Geoblock</Button>
                <Button onClick={this.wf_selection}>Wf Selection</Button>
              </ButtonGroup>
              <CustomInput
                  labelText="Wf Imstream File"
                  id="convertwf"
                  value={this.state.convertWf}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "string",
                    value: this.state.convertWf,
                    onChange: this.handleChange("convertWf"),
                  }}
              />
              <ButtonGroup color="info" aria-label="outlined primary button group">
                <Button onClick={this.mf_matcher}>Mf Matcher</Button>
                <Button onClick={this.mf_none}>None</Button>
              </ButtonGroup>
              <CustomInput
                  labelText="Mf Imstream Folder"
                  id="convertmf"
                  value={this.state.convertMf}
                  formControlProps={{
                    fullWidth: true
                  }}
                  inputProps={{
                    type: "string",
                    value: this.state.convertMf,
                    onChange: this.handleChange("convertMf"),
                  }}
              />

              <CardFooter className={classes.justifyContentCenter}>
                {/* <Button onClick={this.convertandimport} className={this.props.classes.button}  variant="contained">
                  Convert and Import
                </Button> */}
                <Button onClick={this.converImstreams} className={this.props.classes.button}  variant="contained">
                  Convert Imstreams
                </Button>
                <Button onClick={this.importDataToDB} className={this.props.classes.button}  variant="contained">
                  Import Data to DB
                </Button>
              </CardFooter>
            </Card>
        </GridItem>
      </GridContainer>
);
}
}

MiniDashboard.propTypes = {
classes: PropTypes.object.isRequired
};

export default withStyles(loginPageStyle)(MiniDashboard);
