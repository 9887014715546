import { UPDATE_imagesData } from '../actions/actions';

export default function settingsImagesDataReducer(state = '', { type, payload }) {
    switch (type) {
        case UPDATE_imagesData:
            return payload.imagesData;

        default:
            return state;
    }
}
