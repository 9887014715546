export const UPDATE_editMode = 'editModeOn:updateEditMode';
export const UPDATE_editedCount = 'editedCount:updateEditedCount';
export const UPDATE_imagesData = 'imagesData:updateImagesData';
export const UPDATE_editedList = 'editedList:updateEditedList';

export function updateEditMode(newValue) {
    return {
        type: UPDATE_editMode,
        payload: {
            editModeOn: newValue
        }
    }
}
export function updateEditedCount(newValue) {
    return {
        type: UPDATE_editedCount,
        payload: {
            editedCount: newValue
        }
    }
}

export function updateImagesData(newValue) {
    return {
        type: UPDATE_imagesData,
        payload: {
            imagesData: newValue
        }
    }
}

export function updateEditedList(newValue) {
    return {
        type: UPDATE_editedList,
        payload: {
            editedList: newValue
        }
    }
}