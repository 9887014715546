
import React from 'react';
import PropTypes from 'prop-types';
import {Popup} from 'react-mapbox-gl';
import { Typography } from "@material-ui/core";
import turfLength from '@turf/length';
import turfArea from '@turf/area';
import turfCentroid from '@turf/centroid';

export class MeasurePopup extends React.Component {

    render() {

        let {selected} = this.props;

        let area = turfArea(selected) > 100000 ? (turfArea(selected) / 1e6).toFixed(2) + "km" : turfArea(selected).toFixed(2) + " m";
        let length = turfLength(selected, { units: 'meters' }) > 1000 ? turfLength(selected, { units: 'kilometers' }).toFixed(2) + " km"
            : turfLength(selected, { units: 'meters' }).toFixed(2) + " m";
        let centroid = turfCentroid(selected).geometry.coordinates;
        
        return (
            <Popup
                coordinates={centroid}
            >
                <div style={{ textAlign: 'center' }}>
                    <Typography variant="body2"> Measurement</Typography>
                    <hr></hr>
                    <Typography variant="caption">
                        Length: {length}
                    </Typography>
                    <br></br>
                    <Typography variant="caption">
                        Area: {area}<sup>2</sup>
                    </Typography>
                </div>
            </Popup>
        );
    }
}

MeasurePopup.propTypes = {
    selected: PropTypes.object.isRequired,
};

export default MeasurePopup;