/*eslint eqeqeq: [0]*/

/** React import */
import React from "react";

/** Material UI core components */
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Typography } from "@material-ui/core";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DeleteForever from '@material-ui/icons/DeleteForever';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Snackbar from '@material-ui/core/Snackbar';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import TextField from '@material-ui/core/TextField';

/** Other imports */
import styles from 'assets/styles.jsx';
import clsx from 'clsx'

const CssTextField = withStyles({
    root: {
        '& label.Mui-focused': {
            color: 'white',
        },
        '& label': {
            color: '#999999',
        },
        '& .MuiInput-underline:after': {
            borderBottomColor: 'black',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: '#999999',
            },
            '&:hover fieldset': {
                borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'white',
            },
            '&.MuiTextField-root': {
                borderColor: 'white',
            }
        },
    },
})(TextField);

class Sidebar extends React.Component {


    constructor(props) {
        super(props);

        /** Sidebar component local state definition. */
        this.state = {
            drawerOpen: true,
            longtitude: 0,
            latitude: 0,
            Image: 0
        }

        /** Functions bindings. */
        this.handleImageToggle = this.handleImageToggle.bind(this);
        this.GoToImage = this.GoToImage.bind(this);
        this.GetCenterPoint = this.GetCenterPoint.bind(this);
        document.addEventListener('keydown', this.ToggleButtonShortcut.bind(this));

    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.editModeOn !== this.props.editModeOn)
            if (this.props.editModeOn)
                this.handleDrawerClose();
            else
                this.handleDrawerOpen();
    }

    /**
     * This method handles the rendered images toggling based on the checkbox checked parameter value.
     * @param {*} event Checkbox component onClick fired event, it contains the value of the checked parameter either true or false.
     * @public
     */
    handleImageToggle(event) {

        let key = event.target.value;
        if (event.target.checked) {

            this.props.showImage(key);
        }
        else {
            this.props.hideImage(key);
        }

    };

    handleDrawerOpen = () => {
        this.setState({ drawerOpen: true });
    };

    handleDrawerClose = () => {
        this.setState({ drawerOpen: false });
    };

    ToggleButtonShortcut(event) {
        if (event.key === 't' || event.key === 'T') {
            let { renderedImages } = this.props;
            renderedImages.forEach(image => {
                if (image['show'] === true)
                    this.props.hideImage(image['key']);
                else
                    this.props.showImage(image['key']);
            });
        }
    }

    handleChange = name => event => {
        this.setState({ [name]: event.target.value });
    }

    GoToImage(image) {
        image= image+ ".JPG"
        let coords = this.props.imStreamCoords[0]
        
        console.log(this.props.imStreamImages[1000])
        let index = 0
        for (index = 0; index < this.props.imStreamImages.length; index++) { 
            if(image == this.props.imStreamImages[index]){
                coords = this.props.imStreamCoords[index]
            }
        } 
        let center = this.GetCenterPoint(coords);
        this.props.goToCoordinates(center[0],center[1])
    }
    GetCenterPoint(coords){
        let x1 = coords[0][0];
        let y1 = coords[0][1];
        let x2 = coords[2][0];
        let y2 = coords[2][1];
        let x3 = (x1 + x2) / 2;
        let y3 = (y1 + y2) / 2;
        let center = [ x3 , y3 ] ;
        return center;
    }

    render() {
        let {
            renderedImages, imStreamImages,
            loadingImages,
            loadingImstreams, renderedLayers, flightId, currentpos} = this.props

        let { longtitude, latitude } = this.state
        const { classes } = this.props;

        const imstreamsControl = renderedImages.map((renderObject) =>
            <div key={renderObject.key}>
                <FormControlLabel
                    key={renderObject.key}
                    control={<Checkbox style={{ color: 'white' }} onChange={this.handleImageToggle} value={renderObject.key} checked={renderObject.show} />}
                    label={<div>
                        <Typography variant="body2"
                            style={this.props.editModeOn ? { color: '' } : { color: 'white' }}
                            component="p">
                            {imStreamImages[renderObject.key]}</Typography>
                        {loadingImages[renderObject.key] ? <LinearProgress /> : null}
                    </div>}
                    disabled={loadingImages[renderObject.key] || this.props.editModeOn}
                >
                </FormControlLabel>
                <IconButton style={{ float: 'right' }} onClick={() => this.props.warningWithConfirmMessage(renderObject.key)}
                    disabled={this.props.editModeOn}>
                    <DeleteForever htmlColor='white' fontSize="inherit" />
                </IconButton>
            </div>
        )

        return (
            <div>

                <Toolbar>
                    <IconButton
                        style={{ color: "white" }}
                        aria-label="open drawer"
                        onClick={this.handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, this.state.drawerOpen && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={this.state.drawerOpen}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                    style={{ lineHeight: '50%' }}
                >
                    <div className={classes.drawerHeader}>
                        <Typography variant="h6" noWrap>
                            Mapview Control
                </Typography>
                        <IconButton onClick={this.handleDrawerClose}>
                            <ChevronLeftIcon htmlColor='white' />
                        </IconButton>
                    </div>
                    <Divider style={{ background: "#FFFFFF" }} />
                    <br></br>
                    <Divider style={{ background: "#FFFFFF" }} />
                    <br></br>
                    <br></br>

                    <div style={{ justifyContent: 'center', marginLeft: '5%' }}>
                        <Typography style={{ marginLeft: '4vh' }} variant="body2" color="initial" component="p">
                            Flight ID: {flightId}
                        </Typography>
                        <Typography style={{ marginLeft: '4vh' }} variant="body2" color="initial" component="p">
                            Latitude: {currentpos !== null ? currentpos.currentlat.toFixed(4) : 0}
                        </Typography>
                        <Typography style={{ marginLeft: '4vh' }} variant="body2" color="initial" component="p">
                            Longtitude: {currentpos !== null ? currentpos.currentlng.toFixed(4) : 0}
                        </Typography>
                        <Typography style={{ marginLeft: '4vh' }} variant="body2" color="initial" component="p">
                            Zoom Level: {currentpos !== null ? currentpos.currentzoom.toFixed(1) : 0}
                        </Typography>
			<Typography style={{ marginLeft: '4vh' }} variant="body2" color="initial" component="p">
                            Location: {currentpos !== null ? currentpos.currentlocation : "none"}
                        </Typography>
                        <br></br>
                        <Button
                            style={{ marginLeft: '6vh' }} className={this.props.classes.button} variant="contained" size="small"
                            onClick={this.props.shareClicked} disabled={this.props.editModeOn}>
                            Share
                </Button>
                        <br></br>
                        <CssTextField
                            id="outlined-name"
                            label="Latitude"
                            className={classes.textField}
                            onChange={this.handleChange('latitude')}
                            margin="normal"
                            variant="outlined"
                            style={{ marginLeft: '1vh', marginRight: '2vh', foreground: 'white' }}
                            inputProps={{ style: { color: 'white' } }}
                        />
                        <CssTextField
                            id="outlined-name"
                            label="Longtitude"
                            className={classes.textField}
                            onChange={this.handleChange('longtitude')}
                            margin="normal"
                            variant="outlined"
                            style={{ marginLeft: '1vh', marginRight: '2vh' }}
                            inputProps={{ style: { color: 'white' } }}
                        />
                        <Button
                            style={{ marginLeft: '6vh' }} className={this.props.classes.button} variant="contained" size="small"
                            onClick={() => this.props.goToCoordinates(longtitude, latitude)} disabled={this.props.editModeOn}>
                            Go To
                </Button>
                       {/* <CssTextField
                            id="outlined-name"
                            label="Image Number"
                            className={classes.textField}
                            onChange={this.handleChange('Image')}
                            margin="normal"
                            variant="outlined"
                            style={{ marginLeft: '1vh', marginRight: '2vh' }}
                            inputProps={{ style: { color: 'white' } }}
                        />
                        <Button
                            style={{ marginLeft: '6vh' }} className={this.props.classes.button} variant="contained" size="small"
                            onClick={ () => this.GoToImage(this.state.Image)} disabled={this.props.editModeOn}>
                            Go To
                </Button> */}
                <Button
                            style={{ marginLeft: '5.35vh'  }} className={this.props.classes.button} variant="contained" size="small"
                            onClick={this.props.ext_increase_Clicked} disabled={this.props.editModeOn}>
                            2D | 2.5D
                </Button>{/*
                <Button
                            style={{ marginLeft: '1vh' }} className={this.props.classes.button} variant="contained" size="small"
                            onClick={this.props.ext_decrease_Clicked} disabled={this.props.editModeOn}>
                            Decrease Extrusion
                </Button>
                <Button
                            style={{ marginLeft: '4vh' }} className={this.props.classes.button} variant="contained" size="small"
                            onClick={this.props.tree_d_buildings} disabled={this.props.editModeOn}>
                            Change Map
                </Button> */}
                

                
                        {this.props.userRole === 'editor' ?
                            null : null}
                    </div>


                    <br></br>
                    <br></br>

                    <Divider style={{ background: "#FFFFFF" }} />

                    <FormControl style={{ marginLeft: '1vh' }} component="fieldset">
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox style={{ color: 'white' }} onChange={this.props.handleLayerToggle} value="mf" checked={renderedLayers["mf"]} />}
                                label={<Typography variant="body2" style={{ color: '' }} component="p">
                                    Atlas High Resolution Map </Typography>}
                                disabled={this.props.editModeOn}
                            />
                        </FormGroup>
                    </FormControl>
                    <Divider style={{ background: "#FFFFFF" }} />
                    <FormControl style={{ marginLeft: '1vh' }} component="fieldset">
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox style={{ color: 'white' }} onChange={this.props.handleLayerToggle} value="imstreamswf" checked={renderedLayers["imstreamswf"]} />}
                                label={<div><Typography variant="body2"
                                    style={this.props.editModeOn ? { color: '' } : { color: 'white' }}
                                    component="p">
                                    Wide Images </Typography>
                                    {loadingImstreams ? <LinearProgress /> : null}
                                </div>}
                                disabled={loadingImstreams || this.props.editModeOn}
                            />
                            <FormControlLabel
                                control={<Checkbox style={{ color: 'white' }} onChange={this.props.handleLayerToggle} value="imstreamsmf" checked={renderedLayers["imstreamsmf"]} />}
                                label={<div>
                                    <Typography variant="body2"
                                        style={this.props.editModeOn ? { color: '' } : { color: 'white' }}
                                        component="p">
                                        Zoomed Images</Typography>
                                    {loadingImstreams ? <LinearProgress /> : null}
                                </div>}
                                disabled={loadingImstreams || this.props.editModeOn}
                            /> 
                        </FormGroup>
                    </FormControl>
                    <Divider style={{ background: "#FFFFFF" }} />
                    {renderedImages.length !== 0 ? <FormControl style={{ marginLeft: '1vh' }} component="fieldset">
                        <FormGroup>
                            {imstreamsControl}
                        </FormGroup>
                    </FormControl> : null}
                </Drawer>
            </div>
        );
    }
}

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    imStreamImages: PropTypes.array.isRequired,
    renderedImages: PropTypes.array.isRequired,
    renderedLayers: PropTypes.object.isRequired,
    loadingImages: PropTypes.object.isRequired,
    loadingImstreams: PropTypes.bool.isRequired,
    shareClicked: PropTypes.func.isRequired,
    ext_increase_Clicked: PropTypes.func.isRequired,
    ext_decrease_Clicked: PropTypes.func.isRequired,
    tree_d_buildings: PropTypes.func.isRequired,
    handleLayerToggle: PropTypes.func.isRequired,
    deleteImageClicked: PropTypes.func.isRequired,
    showImage: PropTypes.func.isRequired,
    hideImage: PropTypes.func.isRequired,
    warningWithConfirmMessage: PropTypes.func.isRequired,
};

export default withStyles(styles)(Sidebar);
